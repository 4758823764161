import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import store from "@/store/index";
import ysAdmin, {clearPending} from "ys-admin";

Vue.use(VueRouter)

const routes = [
  {path: '/login', name: 'login', meta: {title: '登录', index: '0', hideInMenu: true}, component: () => import('../views/login/Login.vue')},
  {path: '/', name: 'index', redirect: '/main'},
  {
    path: '/main',
    name: 'main',
    redirect: '/main/workStation',
    component: Main,
    meta: {title: '系统首页', index: '1',},
    children: []
  },
  //路由不要写在这个后面
  // {path: '*', name: '404', meta: {hideInMenu: true}, component: () => import('../components/404')},
]

// 声明路由实例
const initRouter = () => new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

// 初始化路由实例
const router = initRouter()


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return (originalPush.call(this, location)).catch((err) => err)
};

router.beforeEach((to, from, next) => {
    ysAdmin.LoadingBar.start();
    clearPending();
    const token = store.getters.token;
    if (!token) {
      if (to.name !== 'login') {
        next({
          name: 'login' // 跳转到登录页
        })
      } else {
        // 未登陆且要跳转的页面是登录页
        next() // 跳转
      }
    } else {
      if (to.name === 'login') {
        // 已登录且要跳转的页面是登录页
        next({
          name: 'main' // 跳转到home页
        })
      } else {
        next()
      }
    }
  }
);
router.afterEach((to) => {
  ysAdmin.LoadingBar.finish();
  let title = '';
  if (to.meta.title) {
    title = (to.meta.title || '') + ' - ' + store.getters.config.title;
  }
  document.title = title;
});

// 重置路由实例, 用于身份验证失败时亦或者退出登录时需重新登录清空当前的路由列表防止重新登录后重复添加路由
export function resetRouter() {
  const newRouter = initRouter()
  router.matcher = newRouter.matcher
}

export default router

<template>
  <div :class="classCls" :style="{'border-radius': (round?width/2:(radius?radius:0))+'px','width':width+'px','height':height+'px','line-height':(height-4)+'px'}">
    <img @click="show=true" :data-src="dataSrc" :src="imgSrc" :class="classCls+'-img '+classCls+'-'+fit" ref="img" v-show="loading===false&&loadError===false">
    <Icon type="md-images" :class="classCls+'-error'" :size="width/2" v-show="loadError===true"/>
    <Icon type="ios-loading" :class="classCls+'-loading'" :size="width/2" v-show="loading===true"/>
  </div>
</template>
<script>
const prefixCls = 'ys-image';

export default {
  name: 'ys-image',
  props: {
    src: {type: String, default: null},
    dataSrc: {type: String, default: null},
    round: {type: Boolean, default: false},
    radius: {type: Number, default: 0},
    width: {type: [Number, String], default: 60},
    height: {type: [Number, String], default: 60},
    header: {
      type: Object, default: () => {
        return {}
      }
    },
    fit: {type: String, default: 'contain'},
  },
  computed: {
    classCls() {
      return prefixCls;
    },
  },
  data() {
    return {
      imgSrc: '',
      loading: true,
      loadError: false,
    }
  },
  watch: {
    'src': function () {
      this.getImg()
    }
  },
  mounted() {
    this.getImg();
  },
  methods: {
    getImg() {
      this.loading = true;
      this.loadError = false;
      let img = this.$refs.img;
      if (Object.keys(this.header).length <= 0) {
        this.imgSrc = this.src;
        img.onload = () => {
          this.loading = false;
        };
        img.onerror = () => {
          this.loading = false;
          this.loadError = true;
        };
      } else {
        let request = new XMLHttpRequest();
        request.responseType = 'blob';
        request.open('get', this.src, true);
        Object.keys(this.header).map((item) => {
          request.setRequestHeader(item, this.header[item]);
        })
        request.onreadystatechange = () => {
          if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
            img.src = URL.createObjectURL(request.response);
            img.onload = () => {
              this.loading = false;
            };
            img.onerror = () => {
              this.loading = false;
              this.loadError = true;
            };
          }
        };
        request.send(null);
      }
    }
  }


}
</script>

<template>
  <CellGroup :class="classes">
    <h1 :class="titleCls" v-if="title">{{ title }}</h1>
    <slot :gutter="currentGutter"></slot>
  </CellGroup>
</template>

<script>
const prefixCls = 'ys-cell-group';

export default {
  name: "ys-cell-group",
  props: {
    card: {type: Boolean, default: false},
    border: {type: Boolean, default: false},
    gutter: {type: Number, default: null},
    inline: {type: Boolean, default: true},
    title: {
      type: [String],
      default: ''
    }
  },
  computed: {
    currentGutter() {
      return this.gutter || (this.inline ? 3 : 4);
    },
    classes() {
      return [prefixCls, `${prefixCls}-${this.currentGutter}`, this.inline ? `${prefixCls}-inline` : '', this.border ? `${prefixCls}-border` : '', this.card ? `${prefixCls}-card` : '']
    },
    titleCls() {
      return [`${prefixCls}-title`]
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
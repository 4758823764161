import Vue from 'vue'
import {formatDate, getBaseDataName, fixed, getRegionByCode} from "ys-admin";

Vue.filter('formatDate', function (date = new Date(), fmt = 'yyyy-MM-dd') {
  if (date) {
    let type = Object.prototype.toString.call(date).slice(8, -1);
    if (type !== 'Date') date = new Date(date);
    return formatDate(date, fmt);
  } else {
    return '';
  }
});
Vue.filter('getBaseDataName', function (value, parentName) {
  if (value != undefined) {
    return getBaseDataName(parentName, value, false);
  } else {
    return '';
  }
});

Vue.filter('fixed', function (value, unitType, num, isColor) {
  if (value!==unitType) {
    return fixed(value, unitType, num, isColor);
  } else {
    return '';
  }
});

Vue.filter('getRegionByCode', function (areaCode) {
  if (areaCode) {
    return getRegionByCode(String(areaCode));
  } else {
    return '';
  }
});
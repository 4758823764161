import axios from 'axios';
import {errorHandle, addPending, removePending} from './axios-function'

axios.headers = [];
axios.defaults.timeout = 300000;

// 请求拦截（配置发送请求的信息）
axios.interceptors.request.use(
    (config) => {
      removePending(config) // 在请求开始前，对之前的请求做检查取消操作
      addPending(config) // 将当前请求添加到 pending 中
      axios.headers.forEach(item => {
        config.headers[item] = sessionStorage.getItem(item) || '';
      });
      config.headers['Content-Type'] = 'application/octet-stream'
      return config;
    },
    (error) => {
      return Promise.reject(error);
    });

// 响应拦截（配置请求回来的信息）
axios.interceptors.response.use(
    res => {
      removePending(res) // 在请求结束后，移除本次请求
      const params = res.config.data ? JSON.parse(res.config.data) : {}
      if (res.data && res.data.code && res.data.code !== 200 && params && !params['noErrorCallback']) {
        errorHandle(res.data.code, res.config.url, res.data.msg)

        return Promise.resolve(res)
      } else {
        return Promise.resolve(res)
      }
    },
    (error) => {
      let {response} = error;
      if (response) errorHandle(response.status, response.config.url)// 请求已发出，但是不在2xx的范围
      if (axios.isCancel(error)) {
        // console.log('repeated request: ' + error.message)
      } else {
        // handle error code
      }
      return Promise.reject(error)
    }
);

/**
 * 封装get方法
 * @param url
 * @param paramsData
 * @returns {Promise}
 */
export default axios;

export function get(url, paramsData) {
  return new Promise((resolve, reject) => {
    axios.get(url, {params: paramsData,}).then((response) => {
      resolve(response.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then((response) => {
      resolve(response.data ? response.data : response)
    }, (err) => {
      reject(err)
    })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 */

export function put(url, data, config) {
  return new Promise((resolve, reject) => {
    axios.put(url, data, config)
        .then((response) => {
          resolve(response.data)
        }, (err) => {
          reject(err)
        })
  })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function del(url, data) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {data: data})
        .then((response) => {
          resolve(response.data)
        }, (err) => {
          reject(err)
        })
  })
}

/**
 * 封装delete请求
 * @param url
 * @param action
 * @returns {Promise}
 */

export function all([...action]) {
  return new Promise((resolve, reject) => {
    axios.all(action).then((res) => {
      resolve(res)
    }, (err) => {
      reject(err)
    });
  })
}
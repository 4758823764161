<template>
  <Drawer
      @on-close="onClose"
      :placement="placement"
      :maskStyle="maskStyle"
      :class="classes"
      @on-visible-change="onVisibleChange"
      @on-close-width="onResize"
      :styles="styles"
      :inner="inner"
      :transfer="inner?false:transfer"
      :width="drawerWidth"
      :height="height"
      :draggable="!mask||draggable"
      :mask="mask"
      :className="className +' drawerMiddle'"
      v-model="modalFlag">
    <div :class="leftFixedCls" @click="modalFlag=false">
      <Icon type="md-arrow-forward" size="20"/>
    </div>
    <Spin fix v-show="loading">
      <Icon type="ios-loading" size="35" class="demo-spin-icon-load"></Icon>
      <div>加载中，请稍后......</div>
    </Spin>
    <slot v-if="!loading&&modalFlag"></slot>
  </Drawer>
</template>

<script>
const prefixCls = 'ys-drawer';

export default {
  name: "ys-drawer",
  props: {
    draggable: {type: Boolean, default: false},
    inner: {type: Boolean, default: false},
    loading: {type: Boolean, default: false},
    mask: {type: Boolean, default: false},
    maskStyle: {type: Object, default: null},
    closable: {type: Boolean, default: true},
    scrollable: {type: Boolean, default: false},
    transfer: {type: Boolean, default: true},
    placement: {type: String, default: 'right'},
    className: {type: String, default: ''},
    maskClosable: {type: Boolean, default: true},
    height: {type: Number, default: 80},
    width: {type: [Number, String], default: 80},
    title: {type: String, default: ''},
    value: {type: Boolean, default: false},
    styles: {type: Object, default: null},
  },
  data() {
    return {
      modalFlag: false,
    }
  },
  watch: {
    value: function (val) {
      if (val === this.modalFlag) return false;
      this.modalFlag = val;
    },
    modalFlag: function (val) {
      this.$emit('input', val)
    }
  },
  computed: {
    classes() {
      return prefixCls
    },
    leftFixedCls() {
      return [`${this.classes}-leftFixed`]
    },
    drawerWidth() {
      if (this.modalFlag) {
        let len = document.getElementsByClassName('ys-detail').length;
        return this.width - 10 * len;
      } else {
        return this.width;
      }

    },
  },
  mounted() {
  },
  methods: {
    onClose() {
      this.$emit('on-close')
    },
    onResize(width) {
      this.$emit('on-resize-width', width)
    },
    onVisibleChange(status) {
      this.$emit('on-visible-change', status)
    }
  }
}
</script>

<style scoped lang="less">
.close {

}
</style>
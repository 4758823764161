import {post, get} from 'ys-admin'
/**
 * 获取附件
 * @param {Object} params
 * @returns {Object}
 */
export const getFile = function (params) {
  return get('/resource/GetFileList',params)
};
/**
 * 登录
 * @param {Object} params
 * @returns {Object}
 */
export const login = function (params) {
  return post('/user/Login', params)
};

/**
 * 登录
 * @param {Object} params
 * @returns {Object}
 */
export const refreshToken = function (params) {
  return post('/auth/RefreshToken', params)
};

/**
 * 登录
 * @param {Object} params
 * @returns {Object}
 */
export const loginAuth = function (params) {
  return post('/auth/Login', params)
};

/**
 * 获取站点配置
 * @returns {Array}
 */
export const getSystemInfo = function () {
  return get('/systemConfig/GetSystemInfo')
};

/**
 * 获取用户信息
 * @param {Object} params
 * @returns {Object}
 */
export const getInfo = function (params) {
  return get('/user/GetInfo',params)
};
/**
 * 获取当前登录用户信息
 * @returns {Object}
 */
export const GetCurrentInfo = function () {
  return get('/user/GetCurrentInfo')
};


/**
 * 获取当前系统字典
 * @returns {Object}
 */
export const getDict = function () {
  return get('/resource/Dict',{noPending:true})
};

/**
 * 通过域名获取平台配置
 * @param {Object} params
 * * @returns {Array}
 */
export const getByDomain = function (params={}) {
  return get('/platformConfig/GetByDomain', params)
};

/**
 * 通过检查资料组详情
 * @param {Object} params
 * @returns {Object}
 */
 export const getDocHistory = function (params = {}) {
  return get('/projects/doc/category/History',params)
};

/**
 * 获取检查标准详情
 * @param {Object} params
 * @returns {Object}
 */
 export const getStandardInfo = function (params = {}) {
  return get('/standard/GetInfo', params)
};

/**
 * 获取检查标准列表
 * @param {Object} params
 * @returns {Object}
 */
 export const getStandardData = function (params = {}) {
  return get('/standard/List', params)
};

/**
 * 项目材料清单
 * @param {Object} params
 * @returns {Object}
 */
 export const getDoc = function (params = {}) {
  return get('/check/doc/ListByProject',params)
};

/**
 * 获取快捷信息
 * @returns {Array}
 */
 export const quickInfoList = function (params) {
  return get('/quickInfo/List',params)
};

/**
 * 查询地图边界数据
 * @param {Object} code
 * @returns {Object}
 */
 export const getGeoJson = function (code) {
  return get('/resource/geojson/' + code + '&time=' + new Date().getTime())
};

/**
 *  获取专项任务检查风险详情
 * @param {Object} params
 * @returns {Object}
 */
 export const getRiskInfo = function (params) {
  return get('/risk/GetInfo', params)
};
import axios from 'axios';
import {Notice} from 'view-design'

/**
 * 动态加载腾讯地图api函数
 * @param {String} key  腾讯地图key，必传
 */
export const loadTMap = (key) => {
  return new Promise((resolve, reject) => {
    if (typeof window.TMap !== "undefined") {
      resolve(window.TMap);
      return true;
    }
    window.onTMapCallback = function () {
      resolve(window.TMap);
      return true;
    };
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://map.qq.com/api/gljs?v=1.exp&key=${key}`;
    script.onerror = reject;
    document.head.appendChild(script);
    return true;
  });
}
/**
 * 计算两个日期之间的天数
 * @param start  开始日期 yyyy-MM-dd
 * @param end  结束日期 yyyy-MM-dd
 * @returns {number} 如果日期相同 返回一天 开始日期大于结束日期，返回0
 */
export const getDaysBetween = (start, end) => {
  let startDate = Date.parse(start);
  let endDate = Date.parse(end);
  if (startDate > endDate) {
    return 0;
  }
  if (startDate === endDate) {
    return 1;
  }
  return (endDate - startDate) / (24 * 60 * 60 * 1000);
}

let $math = require('mathjs');
export const math = {
  add() {
    return comp('add', arguments)
  },
  subtract() {
    return comp('subtract', arguments)
  },
  multiply() {
    return comp('multiply', arguments)
  },
  divide() {
    return comp('divide', arguments)
  },
}

function getType(data) {
  return Object.prototype.toString.call(data).substring(8).split(/]/)[0]
}

/**
 * 获取节点深度
 * @sourceObj {Object}  目标对象；
 * @compareObj {Object}  比较对象
 * @return {Boolean}
 */
export const comparisonObject = (sourceObj, compareObj) => {
  let sourceType = getType(sourceObj);
  if (sourceType !== getType(compareObj)) return false;
  // Not objects and arrays
  if (sourceType !== "Array" && sourceType !== "Object" && sourceType !== "Set" && sourceType !== "Map") {
    if (sourceType === "Number" && sourceObj.toString() === "NaN") {
      return compareObj.toString() === "NaN"
    }
    if (sourceType === "Date" || sourceType === "RegExp") {
      return sourceObj.toString() === compareObj.toString()
    }
    return sourceObj === compareObj
  } else if (sourceType === "Array") {
    if (sourceObj.length !== compareObj.length) return false;
    if (sourceObj.length === 0) return true;//???
    for (let i = 0; i < sourceObj.length; i++) {
      if (!comparisonObject(sourceObj[i], compareObj[i])) return false;
    }
  } else if (sourceType === "Object") {
    // 通过Reflect.ownKeys得到属性名组成的数组
    let sourceKeyList = Reflect.ownKeys(sourceObj);
    let compareKeyList = Reflect.ownKeys(compareObj);
    let key;
    if (sourceKeyList.length !== compareKeyList.length) return false;
    for (let i = 0; i < sourceKeyList.length; i++) {
      key = sourceKeyList[i];
      // 比较属性名
      if (key !== compareKeyList[i]) return false;
      // 比较属性值
      if (!comparisonObject(sourceObj[key], compareObj[key])) return false;
    }
  } else if (sourceType === "Set" || sourceType === "Map") {
    // 把 Set Map 转为 Array
    if (!comparisonObject(Array.from(sourceObj), Array.from(compareObj))) return false;
  }
  return true;
}

function comp(_func, args) {
  let t = $math.chain($math.bignumber(args[0]));
  for (let i = 1; i < args.length; i++) {
    t = t[_func]($math.bignumber(args[i]))
  }
  // 防止超过6位使用科学计数法
  return parseFloat(t.done())
}

/**
 * 获取节点深度
 * @param {array} 目标数组；
 * @param {childKey} 子集key
 * @return {number}
 */
export const getMaxFloor = (treeData, childKey = 'children') => {
  let deep = 0, maxDeep = 0;

  const eachData = (data, index) => {
    data.forEach(elem => {
      if (index > deep) {
        deep = index;
      }
      if (Array.isArray(elem[childKey]) && elem[childKey].length > 0) {
        eachData(elem[childKey], deep + 1);
      } else {
        maxDeep = maxDeep > deep ? maxDeep : deep;
        deep = 1;
      }
    })
  }

  eachData(treeData, 1);
  return maxDeep;
}
/**
 * 生成临时社会信用代码18位
 * @return {string}
 */
export const generateSocialCreditCode = () => {
  const date = new Date()
  return 'T' + date.getFullYear() + date.getTime()
}
/**
 * 获取节点深度
 * @param {array} 目标数组；
 * @param {childKey} 子集key
 * @return {number}
 */
export const blobDownFile = (data, fileName) => {
  if (data.type === 'application/json') {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      const jsonData = JSON.parse(fileReader.result)
      if (jsonData.code) {
        Notice.error({
          title: "操作失败！",
          desc: jsonData.msg
        });
      }
    }
    fileReader.readAsText(data);
  } else {
    const blob = new Blob([data], {type: 'application/octet-stream'});
    //IE10以上支持blob但是依然不支持download
    if ('download' in document.createElement('a')) { //支持a标签download的浏览器
      const link = document.createElement('a');//创建a标签
      link.download = fileName;
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } else { //其他浏览器
      navigator.msSaveBlob(blob, fileName)
    }
  }
}
/**
 * 数组扁平化
 * @target {array} 目标数组；
 * @childKey {string} 基础配置
 * @return {array}
 */
export const flatten = (arr, childKey = 'children') => {
  return arr.reduce((result, item) => {
    return result.concat(item, (Array.isArray(item[childKey]) ? flatten(item[childKey]) : []));
  }, []);
}

/**
 * list转tree
 * @target {array} 目标数组；
 * @config {object} 基础配置
 * @return {array}
 */
export const listToTree = (target, config) => {
  if (!Array.isArray(target)) return [];
  config.children = config.children || 'children';//子集集合key名
  config.parentKey = config.parentKey || 'parentID';//父级key名
  config.sonKey = config.sonKey || 'id';//子集key名
  config.addProperties = config.addProperties || null;//追加属性
  config.topLevelVal = config.topLevelVal || 0;//顶级数据名  对应sonKey的值

  let result = [], targetMap = new Map(), treeObj = {};
  target.forEach(item => {
    if (!targetMap.has(item[config.parentKey])) {
      treeObj[item[config.parentKey]] = [];
    }
    if (config.addProperties) {
      Object.keys(config.addProperties).forEach(addItem => {
        item[addItem] = config.addProperties[addItem];
      })
    }
    treeObj[item[config.parentKey]].push(item);
    if (item[config.parentKey] === config.topLevelVal) result.push(item);
    targetMap.set(item[config.parentKey], treeObj[item[config.parentKey]]);
  });
  let loop = (array) => {
    return array.map((item) => {
      item[config.children] = [];
      if (targetMap.has(item[config.sonKey])) {
        let children = clone(targetMap.get(item[config.sonKey]));
        targetMap.delete(item[config.sonKey]);
        if (targetMap.size > 0 && children.length > 0) item[config.children] = loop(children);
      }
      return item
    })
  }
  return loop(result)
}

/**
 * 查询目标数据在目标数组的路劲ID
 * @param {Object} config 所需参数
 * @returns {Array}
 */

export function getRecorderAryByDeep(config) {
  config = {
    targetData: config.targetData,
    targetValue: config.targetValue,
    label: config.label || {valueKey: 'id', childKey: 'children', resultKey: 'id'}
  }
  let result = [];
  let checkFlag = false;
  const deepFor = (data = []) => {
    for (let i = 0; i < data.length; i++) {
      result.push(data[i][config.label.resultKey || config.label.valueKey])
      if (data[i][config.label.valueKey] === config.targetValue) {
        checkFlag = true
        return false;
      } else {
        if (data[i][config.label.childKey] && data[i][config.label.childKey].length > 0) {
          deepFor(data[i][config.label.childKey]);
        }
        if (!checkFlag) {
          result.splice(result.length - 1, 1)
        } else {
          return false;
        }
      }
    }
  }
  deepFor(config.targetData || []);
  return result
}

/**
 * 深拷贝，已经考虑递归引用以及内存溢出问题
 * @target {any} 目标；
 * @map {Map} map对象，用于保存递归引用的数据，防止内存溢出
 * @returns {any}
 */
export const clone = (target, map = new Map) => {
  if (typeof target !== 'object') {
    return target;
  } else {
    let type = Object.prototype.toString.call(target).slice(8, -1);
    let cloneTarget = null;
    if (map.get(target)) {
      return map.get(target);
    }
    map.set(target, target);
    const regFlag = /\w*$/;
    switch (type) {
      case 'Object':
      case 'Array':
        cloneTarget = Array.isArray(target) ? [] : {};
        for (let key in target) {
          cloneTarget[key] = clone(target[key], map);
        }
        break;
      case 'Date':
        cloneTarget = new Date(target);
        break;
      case 'RegExp':
        cloneTarget = new RegExp().constructor(target.source, regFlag.exec(target));//可用，但webstorm会吧new RegExp()标红显示错误，不知道为什么！
        cloneTarget.lastIndex = target.lastIndex;
        break;
      default:
        cloneTarget = target;
        break
    }
    return cloneTarget;
  }
};

/**
 * 标准时间转换成日期字符串
 * @param {String} areaCode 地区code；
 * @param separator 分隔符
 * @param type 1：name，2：code
 * @returns {String}
 */
export const getRegionByCode = function (areaCode, separator = '-', type = 1) {
  let checkFlag = false, result = [];
  let getDeepRegion = (data) => {
    for (let i = 0; i < data.length; i++) {
      result.push(data[i])
      if (data[i].code === areaCode) {
        checkFlag = true
        return false;
      } else {
        if (data[i].children && data[i].children.length > 0) {
          getDeepRegion(data[i].children);
        }
        if (!checkFlag) {
          result.splice(result.length - 1, 1)
        } else {
          return false;
        }
      }
    }
  }
  if (areaCode) {
    getDeepRegion(window['regionData'] || []);
    let regionName = '';
    result.forEach((item) => {
      regionName = type === 1 ? (regionName ? (regionName + separator + item.name) : item.name) : (regionName ? (regionName + separator + item.code) : item.code)
    });
    return regionName;
  } else {
    return '';
  }
};

/**
 * 寻找当前coed下的所有子级区域
 * @param {String} areaCode 地区code；
 * @param {Boolean} tileAll 是否平铺所有子孙节点
 * @returns {String}
 */
export const getRegionByCodeOfChildren = function (areaCode, tileAll = false) {
  let list = []
  // 寻找当前code
  const findCode = function (data) {
    return data.forEach(item => {
      if (item.code * 1 === areaCode * 1) {
        if (tileAll) {
          tileAllChildren(item.children)
        } else {
          list = item.children
        }
      } else if (item.children && item.children.length > 0) {
        return findCode(item.children)
      } else {
        return false
      }
    })
  }
  // 平铺当前code下所有子孙
  const tileAllChildren = function (data) {
    return data.forEach(item => {
      list.push(item)
      if (item.children && item.children.length > 0) {
        return tileAllChildren(item.children)
      } else {
        return false
      }
    })
  }
  if (areaCode) {
    findCode(window['regionData'])
  } else {
    list = [];
  }
  return list
}


/**
 * 文件流导出文件
 * @param {Object} config 必要参数；
 * @returns {File}
 */
export const downByFileStream = (config) => {
  return new Promise((resolve, reject) => {
    axios({
      method: config.method,
      url: config.url,
      data: config.params,
      responseType: 'blob',//定义接口响应的格式,很重要
    }).then((response) => {
      blobDownFile(response.data, config.fileName)
      resolve(true)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 标准时间转换成日期字符串
 * @param {Date} date date日期；
 * @param {String} fmt 要转换成的格式 yyyy-MM-dd hh:mm:ss；
 * @returns {String}
 */
export const formatDate = (date, fmt = 'yyyy-MM-dd') => {
  if (!date) return '';
  if (!(date instanceof Date)) date = new Date(date.replace(/-/g, '/'));
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
    }
  }
  return fmt;
};

/**
 * 根据数据项名称和value值获取数据项名
 * @param {String} parentName 数据项名称；
 * @param {Number} value 数据项值；
 * @param isHtml
 * @returns {String}
 */
export const getBaseDataName = (parentName, value, isHtml = true) => {
  let resultData = [];
  let result = '';
  let baseDataList = window.baseData.get(parentName) || [];
  resultData = baseDataList.find((item) => item.value === value);
  if (resultData) {
    if (isHtml) {
      result = "<span style='color:" + resultData['color'] + "'>" + resultData['name'] + '</span>';
    } else {
      result = resultData['name'];
    }
  }
  return result;
};
/**
 * 根据数据项名称和value值获取数据项
 * @param {String} parentName 数据项名称；
 * @param {Number} value 数据项值；
 * @returns {Object}
 */
export const getBaseData = (parentName, value) => {
  let resultData = [];
  let result = '';
  let baseDataList = window.baseData.get(parentName) || [];
  resultData = baseDataList.find((item) => item.value === value);
  if (resultData) {
    result = resultData;
  }
  return result;
};

/**
 * 生成uuid
 * @param {Number} len 长度；
 * @param {Number} radix 进制  2位2进制  10 为10进制  16为16进制；
 * @returns {String}
 * 结果  String
 **/
export function uuid(len = 32, radix = 16) {
  let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  let uuid = [], i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    // rfc4122, version 4 form
    let r;
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('');
}

/**
 * 单位转换
 * 函数名 fixed
 * 参数  [value:Number]、[num:Number]、[unitType:Number]
 * 结果  String
 **/
export function fixed(value, unitType, num = 2, isColor = false) {
  let unit = '元';
  switch (unitType) {
    case 1:
      unit = '';
      break;
    case 2:
      unit = '㎡';
      break;
    case 3:
      unit = '月';
      break;
    case 4:
      unit = '人';
      break;
    case 5:
      unit = '%';
      break;
    case 6:
      unit = '日';
      break;
    case 7:
      unit = '‰';
      break;
    case 8:
      unit = '天';
      break;
    case 9:
      unit = '元';
      break;
    case 10:
      unit = '万元';
      break;
    case 11:
      unit = 'm';
      break;
    default:
      unit = '元';
      break;
  }
  if (value !== undefined && value !== null) {
    if (unit === '万元' || unit === '元') {
      return scienceCount(value, num, isColor, unit)
    } else {
      return value.toFixed(num) + unit;
    }
  } else {
    return (0).toFixed(num) + unit;
  }
}

/**
 * 科学计数.千分位分割法
 * @param {String} value 数据项值
 * @param {Number} retainNum 小数点保留位数
 * @param {Boolean} isColor 是否需要颜色区分
 * @param {String} unit 单位
 * @returns {Object} 经过处理后的数值以及相对应的颜色
 **/
export function scienceCount(value, retainNum = 0, isColor = false, unit) {
  const targetValue = value.toFixed(retainNum)
  let frontStr = targetValue.split('.')[0]
  let afterStr = targetValue.split('.')[1]
  const reversalValueList = frontStr.split('').reverse();
  const colorList = ['#43b244', '#fba414', '#ed5a65', '#f5391c', '#ee2c79', '#fc00ff'] // 千，百万，十亿，万亿，千万亿，百兆
  let str = ''
  let num = 0
  for (let index = 0; index < reversalValueList.length; index++) {
    if (index % 3 === 0) {
      num++
      str += [...reversalValueList.slice(index, index + 3), ','].join('')
    }
  }
  if (num > colorList.length) num = colorList.length
  // return {
  //   value: str.slice(0, str.length - 1).split('').reverse().join('') + (afterStr ? `.${afterStr}` : ''),
  //   color: colorList[num - 1]
  // }
  return `<span style="color: ${isColor ? colorList[num - 1] : ''};">${str.slice(0, str.length - 1).split('').reverse().join('') + (afterStr ? `.${afterStr}` : '')}${unit}</span>`
}

/**
 * 剔除字符串空格
 * @param {String} value 数据项值
 * @param {String} pos 空格去除位置可选 left, right, all, around
 * @returns {Object} 经过处理后的数值以及相对应的颜色
 **/
export function strReword(value, pos = 'around') {
  let str = ''
  switch (pos) {
    case 'left':
      str = value.replace(/^\s*/g, '')
      break;
    case 'right':
      str = value.replace(/\s*$/g, '')
      break;
    case 'all':
      str = value.replace(/\s*/g, '')
      break;
    case 'around':
      str = value.replace(/^\s*|\s*$/g, '')
      break;
  }
  return str
}

/**
 * 创建websocket连接
 * @param {String} host ws连接域名
 * @param {String} data 请求数据
 * @returns {Object} 经过处理后的数值以及相对应的颜色
 **/
export function connectSocket(host = '', data = {}) {
  let reloadTime = 3, connecting = false;/*防止close和error都触发重连*/
  const reg = /^((([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5])\.){3}([1-9]?\d|1\d{2}|2[0-4]\d|25[0-5]))$/
  // 非开发环境直接从地址栏获取host地址
  if (host === '' || !(reg.test(location.host.split(':')[0]) || location.host.split(':')[0].indexOf('localhost') !== -1)) host = location.host
  const {types1, types2} = data
  webSocketInit();

  function webSocketInit() {
    connecting = false;
    const protocol = location.protocol !== 'https:' ? 'ws' : 'wss';
    const ws = window.webSocket = new WebSocket(`${protocol}://${host}/web/ws`);
    ws.onopen = () => {
      // 调用订阅消息的接口
      setTimeout(() => {
        ws.send(JSON.stringify(types1));
        ws.send(JSON.stringify(types2));
      }, 500)
    };
    // 连接关闭
    ws.onclose = () => {
      // reconnect();
      // connecting = true;
    };
    // 接收服务器推送消息
    ws.onmessage = (res) => {
      const data = JSON.parse(res.data)
      if (data.url !== '/sys/push') return
      Notice.info({
        title: data.url === '/subscribe/mine' ? '我的消息通知' : '机构消息通知',
        desc: data.data ? JSON.parse(data.data).message : ''
      });
    };
    // 连接发生错误时
    ws.onerror = () => {
      reconnect();
      connecting = true;
    }
  }

  // 重连
  function reconnect() {
    if (reloadTime > 0 && !connecting) {
      setTimeout(function () {
        reloadTime--;
        webSocketInit();
      }, 2000);
    }
  }
}

import { render, staticRenderFns } from "./column.vue?vue&type=template&id=534f88ae&scoped=true&"
import script from "./column.vue?vue&type=script&lang=js&"
export * from "./column.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../ys-manager/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534f88ae",
  null
  
)

export default component.exports
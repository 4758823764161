<template>
  <div :class="classes">
    <div :class="tagListCls" ref="tag">
      <div class="tag" v-for="(item,index) in pageTabList" :key="index" :class="{'active':$route.path===item.path}">
        <Tooltip trigger="hover" :max-width="300" :theme="set.theme" :delay="1000">
          <div class="moreItem" slot="content">
            <Icon :type="item.meta.icon" size="18"/>
            <div class="content">
              <p>{{ item.meta.title }} ·
                <Time :time="item.lastTime"/>
              </p>
              <p class="lastTime"> {{ host + routerMode + item.path }} </p>
            </div>
          </div>
          <div class="navItem">
            <router-link :to="item.path" active-class="active">
              <Icon :type="item.meta.icon" size="16"></Icon>
              <span v-if="!item.meta.home">{{ item.meta.title }}</span>
            </router-link>
            <Icon type="md-close" @click="delTag(index,$event)" size="16" style="" v-if="!item.meta.home"/>
          </div>
        </Tooltip>
      </div>
    </div>
    <Dropdown placement="bottom-end" @on-click="moreDrop" style="margin-right: 5px;" v-if="moreIndex">
      <div :class="tagCls">
        <Icon type="md-funnel" size="14"/>
      </div>
      <DropdownMenu slot="list">
        <DropdownItem :name="item.path" v-for="(item,index) in morePageList" :key="item.path">
          <div class="moreItem" :class="{'active':$route.path===item.path}">
            <Icon :type="item.meta.icon" size="18"/>
            <div class="content">
              <p>{{ item.meta.title }} ·
                <Time :time="item.lastTime"/>
              </p>
              <p class="lastTime"> {{ host + routerMode + item.path }} </p>
            </div>
            <Icon type="md-close" class="close" @click="delTag(moreIndex+index,$event)" size="16" style="" v-if="!item.meta.home"/>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <Dropdown placement="bottom-end" @on-click="closeItem">
      <div :class="tagCls">
        <Icon type="ios-arrow-down" size="14"/>
      </div>
      <DropdownMenu slot="list">
        <DropdownItem name="left">
          <Icon type="md-arrow-back"/>
          关闭左侧
        </DropdownItem>
        <DropdownItem name="right">
          <Icon type="md-arrow-forward"/>
          关闭右侧
        </DropdownItem>
        <DropdownItem name="other">
          <Icon type="md-close"/>
          关闭其他
        </DropdownItem>
        <DropdownItem name="all">
          <Icon type="md-close-circle"/>
          关闭所有
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
import {formatDate, math} from '../../../lib/index.js'

const prefixCls = 'ys-container-tag';

export default {
  name: "Tag",
  computed: {
    classes() {
      return prefixCls
    },
    tagListCls() {
      return [`${this.classes}-tagList`]
    },
    tagCls() {
      return [`${this.classes}-down`]
    },
    morePageList() {
      return this.moreIndex ? this.pageTabList.filter((item, index) => index >= this.moreIndex) : [];
    },
    routerMode() {
      return this.$router.mode === 'hash' ? '/#' : '';
    },
    host() {
      return window.location.host;
    },
    set() {
      return JSON.parse(localStorage.getItem('set') || '{}');
    }
  },
  props: {
    disabledCloseName: String,
  },
  data() {
    return {
      pageTabList: null,
      moreIndex: null,
      firstVisit: true,
    }
  },
  watch: {
    '$route': function (data) {
      this.setTag(data)
    },
  },
  mounted() {
    this.setTag(this.$router.currentRoute);
    window.onresize = () => {
      this.setTagWidth();
    }
  },
  methods: {
    setTagWidth() {
      this.$nextTick(() => {
        this.moreIndex = null;
        const $tag = this.$refs.tag;
        let tagWidth = 0;
        for (let i = 0; i < $tag.children.length; i++) {
          let ele = $tag.children[i];
          ele.style.display = '';
          let marginRight = parseInt(window.getComputedStyle(ele).marginRight.split('px')[0]);
          let marginLeft = parseInt(window.getComputedStyle(ele).marginLeft.split('px')[0]);
          tagWidth = math.add(tagWidth, ele['offsetWidth'], marginRight, marginLeft);
          if (tagWidth > $tag.offsetWidth - 60) {
            if (!this.moreIndex) this.moreIndex = i;
            ele.style.display = 'none';
          }
        }
        if (this.firstVisit) {
          this.setTagWidth();
          this.firstVisit = false;
        }
      })
    },
    setTag(router) {
      this.pageTabList = localStorage.getItem('pageTabList') ? JSON.parse(localStorage.getItem('pageTabList')) : [];
      if (this.pageTabList.length === 0) {
        let homeRouter = this.$router.getRoutes().filter(item => item.meta.home);
        if (homeRouter.length > 0) {
          this.pageTabList.push({
            path: homeRouter[0].path,
            name: homeRouter[0].name,
            meta: homeRouter[0].meta,
            lastTime: formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
          })
        }
      }
      let isExist = false;
      this.pageTabList.forEach(item => {
        if (item.path === router.path) {
          item.lastTime = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
          isExist = true;
        }
      });
      if (!isExist) {
        let data = {
          path: router.path,
          name: router.name,
          meta: router.meta,
          lastTime: formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss'),
        };
        if (data.meta.home) {
          this.pageTabList.unshift(data);
        } else {
          this.pageTabList.push(data)
        }
      }
      localStorage.setItem('pageTabList', JSON.stringify(this.pageTabList));
      this.setTagWidth();
    },
    moreDrop(path) {
      this.pageTabList.forEach(item => {
        if (item.path === path) item.lastTime = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
      });
      localStorage.setItem('pageTabList', JSON.stringify(this.pageTabList));
      this.$router.push({
        path: path
      })
    },
    closeItem(name) {
      let currentIndex = 1, currentPage, len = 1;
      this.pageTabList.forEach((item, index) => {
        if (item.name === this.$router.currentRoute.name) {
          currentIndex = index;
          if (index !== 0) {
            currentPage = this.pageTabList[index];
            currentPage.lastTime = formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
          }
        }
      })
      switch (name) {
        case 'left':
          len = currentIndex - 1;
          currentIndex = 0;
          break
        case 'right':
          len = this.pageTabList.length - currentIndex - 1;
          break;
        case 'all':
        case 'other':
          len = this.pageTabList.length - 1;
          currentIndex = 0;
          break
      }
      this.pageTabList.splice(currentIndex + 1, len);
      if (name === 'other' && currentPage) this.pageTabList.push(currentPage);
      localStorage.setItem('pageTabList', JSON.stringify(this.pageTabList))
      if (name === 'all') {
        this.$router.push({
          name: this.pageTabList[0].name
        })
      }
      this.setTagWidth();
    },
    delTag(index, e) {
      if (this.pageTabList[index].name === this.$router.currentRoute.name) {
        this.$router.push({
          name: this.pageTabList[index - 1].name
        })
      }
      this.pageTabList.splice(index, 1);
      localStorage.setItem('pageTabList', JSON.stringify(this.pageTabList));
      this.setTagWidth();
      window.event ? window.event.cancelBubble = true : e.stopPropagation();
    }
  }
}
</script>
<template>
  <ul :class="classCls" v-viewer="{url: 'data-src'}">
    <li :class="itemCls" :style="{'flex-basis':gutter?'calc('+(100/gutter)+'% - 15px)':(width+'px'),height:height+'px'}" v-for="(item,index) in defaultFileList" :key="index">
      <div>
        <YSImage :data-src="item.url" :src="item.url+'?size=100'" :width="width" :height="height" v-if="imgTypeList.indexOf(item.suffix)>=0"></YSImage>
        <YSImage :data-src="fileType[item.suffix]" :src="fileType[item.suffix]" :width="width" :height="height" v-else-if="fileType[item.suffix]"/>
        <YSImage :src="fileType['NONE']" :width="width" :height="height" v-else/>
      </div>
      <div :class="infoCls" v-if="type==='all'">
        <h3 :class="fileNameCls">{{ item.fileName }}</h3>
        <p>{{ item.createdAt }}</p>
      </div>
      <a @click="downFile(item)" href="javascript:" :class="downCls" title="下载" v-if="type==='all'">
        <Icon type="md-cloud-download"/>
        下载
      </a>
    </li>
  </ul>
</template>

<script>
import download from "downloadjs"

const prefixCls = 'ys-file-list';

export default {
  name: "ys-file-list",
  components: {
    YSImage: () => import('../ys-image/index.vue')
  },
  props: {
    fileList: {type: Array, default: () => []},
    fileIds: {type: Array, default: () => []},
    type: {type: String, default: 'all'},//all 显示全部，onlyFile 仅显示文件图标/图片
    width: {type: Number, default: 150},
    height: {type: Number, default: 150},
    gutter: {type: Number, default: 2},
  },
  computed: {
    classCls() {
      return prefixCls;
    },
    itemCls() {
      return [`${prefixCls}-item`];
    },
    infoCls() {
      return [`${this.itemCls}-info`];
    },
    fileNameCls() {
      return [`${this.infoCls}-fileName`];
    },
    downCls() {
      return [`${this.itemCls}-down`];
    },
  },
  watch: {
    fileList: function () {
      this.getSuffix();
    },
    fileIds: function () {
      this.getFileList();
    },
  },
  data() {
    return {
      defaultFileList: [],
      fileType: {
        AVI: require('../../assets/images/AVI.png'),
        CDR: require('../../assets/images/CDR.png'),
        CSS: require('../../assets/images/CSS.png'),
        DOC: require('../../assets/images/DOC.png'),
        DOCX: require('../../assets/images/DOCX.png'),
        PDF: require('../../assets/images/PDF.png'),
        MOV: require('../../assets/images/MOV.png'),
        HTML: require('../../assets/images/HTML.png'),
        MP3: require('../../assets/images/MP3.png'),
        MP4: require('../../assets/images/MP4.png'),
        PHP: require('../../assets/images/PHP.png'),
        PPT: require('../../assets/images/PPT.png'),
        EPS: require('../../assets/images/EPS.png'),
        PSD: require('../../assets/images/PSD.png'),
        RAR: require('../../assets/images/RAR.png'),
        TTF: require('../../assets/images/TTF.png'),
        TXT: require('../../assets/images/TXT.png'),
        XLS: require('../../assets/images/XLS.png'),
        XLSX: require('../../assets/images/XLSX.png'),
        ZIP: require('../../assets/images/ZIP.png'),
        NONE: require('../../assets/images/unknown.png'),
      },
      imgTypeList: ['JPG', 'GIF', 'PNG', 'JPEG'],
    }
  },
  mounted() {
    this.defaultFileList =this.fileList;
    if (this.fileIds.length > 0) {
      this.getFileList()
    }else{
      this.getSuffix()
    }
  },
  methods: {
    downFile(item){
      const request = new XMLHttpRequest()
      request.open('GET', item.url)
      request.responseType = 'blob'
      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          console.log(request.response)
          download(request.response, item.fileName)
        }
      }
      request.send()
    },
    getFileList() {
      this.$get('/resource/GetFileByIds', {ids: this.fileIds}).then(res => {
        this.defaultFileList=this.defaultFileList.concat(res.data||[]);
        this.getSuffix();
      });
    },
    getSuffix() {
      let splitAry;
      this.defaultFileList.forEach(item => {
        if (item.fileName) {
          splitAry = item.fileName.split('.');
          item.suffix = (item.suffix || splitAry[splitAry.length - 1]).toUpperCase();
        }
      })


    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div :class="classes">
      <div class="search">
        <AutoComplete
            v-model="keyword"
            clearable
            :icon="!loading?'ios-search':'ios-loading'"
            placeholder="关键词搜索"
            @on-change="searchSuggest"
            style="width:300px">
          <Option v-for="option in suggestionList" :value="option.title" :key="option.id">
            <p>{{ option.title }}</p>
            <p style="color: #959595;font-size: 12px">{{ option.address }}</p>
          </Option>
        </AutoComplete>
      </div>
      <div class="latlng">当前坐标：{{ latValue + ',' + lngValue }}</div>
      <img class="location" src="../../assets/images/location.png" alt="">
      <div id="container"></div>
    </div>
  </div>
</template>

<script>
const prefixCls = 'ys-map';
let timer = null;
export default {
  name: "ys-cell",
  props: {
    lat: {type: Number, default: null},
    lng: {type: Number, default: null},
  },
  computed: {
    classes() {
      return prefixCls
    },
  },
  data() {
    return {
      map: null,
      keyword: '',
      suggestionList: [],
      latValue: null,
      lngValue: null,
      suggest: null,
      loading: false,
    }
  },
  watch: {
    loading: function () {
      if (this.loading === false && this.suggestionList.length >= 1) {
        this.latValue = this.suggestionList[0].location.lat;
        this.lngValue = this.suggestionList[0].location.lng;
        this.$emit('on-ok', this.latValue, this.lngValue)
        // eslint-disable-next-line
        this.map.setCenter(new TMap.LatLng(this.latValue, this.lngValue))
      }
    }
  },
  mounted() {
    this.latValue = this.lat;
    this.lngValue = this.lng;
    this.init()
  },
  methods: {
    searchSuggest() {
      window.clearTimeout(timer);
      if (!this.keyword) return;
      timer = window.setTimeout(() => {
        this.loading = true;
        // eslint-disable-next-line
        let suggest = new TMap.service.Suggestion({pageSize: 10});
        suggest.getSuggestions({keyword: this.keyword}).then((result) => {
          // 以当前所输入关键字获取输入提示
          this.suggestionList = result.data;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        });
      }, 300)
    },
    init() {
      //初始化地图
      // eslint-disable-next-line
      this.map = new TMap.Map("container", {
        zoom: 16,//设置地图缩放级别
        // eslint-disable-next-line
        center: new TMap.LatLng(this.lat, this.lng)
      });
      // eslint-disable-next-line
      this.map.on("panend", () => {
        const location = this.map.getCenter()
        this.latValue = location.lat;
        this.lngValue = location.lng;
        this.$emit('on-ok', this.latValue, this.lngValue)
      })
    }
  }
}
</script>

<style scoped lang="less">
.ys-map {
  position: relative;

  #container {
    height: 70vh;
  }

  .location {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -17px;
    z-index: 1;
    width: 34px;
  }

  .latlng {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px 10px;
    color: #FFFFFF;
  }

  .search {
    position: absolute;
    z-index: 10000;
    left: 10px;
    top: 10px;
  }

}

</style>
<template>
  <div>
    <div class="logo"><img :src="logo" alt="" class="pic"/></div>
    <ys-cell-group>
      <ys-cell title="机构名称" block :label="detail.iname"></ys-cell>
      <ys-cell title="社会信用代码" :label="detail.societyCode"></ys-cell>
      <ys-cell title="登记机关" :label="detail.belongOrg"></ys-cell>
      <ys-cell title="法人" :label="detail.operName"></ys-cell>
      <ys-cell title="注册资本" :label="detail.registCapi"></ys-cell>
      <ys-cell title="实缴资本" :label="detail.recCap"></ys-cell>
      <ys-cell title="企业类型" :label="detail.econKind"></ys-cell>
      <ys-cell title="企业性质" :label="detail.entType|getBaseDataName('组织类型')"></ys-cell>
      <ys-cell title="成立日期" :label="detail.startDate"></ys-cell>
      <ys-cell title="工商注册号" :label="detail.no"></ys-cell>
      <ys-cell title="组织机构代码" :label="detail.orgNo"></ys-cell>
      <ys-cell title="登记状态" :label="detail.status"></ys-cell>
      <ys-cell title="吊销日期" :label="detail.endDate"></ys-cell>
      <ys-cell title="所在地区" :label="(detail.countyCode || detail.cityCode || detail.provinceCode)|getRegionByCode"></ys-cell>
      <ys-cell title="营业期限" :label="detail.termStart+'至'+(detail.termEnd||'')"></ys-cell>
      <ys-cell title="是否上市" :label="parseInt(detail.isOnStock)|getBaseDataName('是否')"></ys-cell>
      <ys-cell title="注册地址" :label="detail.address" block></ys-cell>
      <ys-cell title="经营范围" :label="detail.scope" block></ys-cell>
    </ys-cell-group>
  </div>
</template>

<script>
import {get} from '../../request/index'

export default {
  name: "baseInfo",
  props: {
    businessDetail: {
      type: [Object], default: () => null
    },
    mid: {type: [String], default: null},
  },
  data() {
    return {
      detail: {}
    }
  },
  computed: {
    logo() {
      return this.detail.imageUrl ? this.detail.imageUrl.split('?')[0] : ''
    },
  },
  mounted() {
    if (this.businessDetail) {
      this.detail = this.businessDetail;
    }else{
      if (this.mid) {
        this.getDetail();
      }
    }
  },
  methods: {
    getDetail() {
      get('/business/GetByUuid', {uuid: this.mid}).then(res => {
        if (res.code === 200) this.detail = res.data;
      })
    },
  }
}
</script>

<style scoped lang="less">
.logo {
  text-align: center;
  padding: 15px 0;

  .pic {
    width: 100px;
  }
}
</style>
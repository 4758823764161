<template>
  <div :class="classCls">
    <Icon :type="icon" size="35" :style="iconStyle"/>
    <p class="title" v-if="title!==false">{{ title }}</p>
  </div>
</template>

<script>
const prefixCls = 'ys-empty';

export default {
  name: "ys-empty",
  props: {
    title: {
      type:[String,Boolean],
      default: '暂无数据'
    },
    iconStyle:{
      type:Object,
      default:()=>{
        return null
      }
    },
    icon: {
      type: [String],
      default: 'ios-albums-outline'
    }
  },
  computed: {
    classCls() {
      return prefixCls
    }
  }
}
</script>

<style scoped>

</style>
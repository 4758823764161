<template>
  <Modal
      v-model="modShow"
      :width="maximizeEnable&&windowSize==='minimize'?'300px':width"
      :footer-hide="footerHide"
      :mask-closable="maskClosable"
      :fullscreen="maximizeEnable&&windowSize==='maximize'?true:fullscreen"
      :z-index="zIndex"
      :closable="closable"
      :draggable="draggable"
      :mask="maximizeEnable&&windowSize==='minimize'?false:mask"
      :ok-text="okText"
      :class-name="'modal '+ (maximizeEnable&&windowSize==='minimize'?'noPadding':'')"
      @on-ok="$emit('on-ok')"
      :transfer="transfer"
      :styles="maximizeEnable&&windowSize==='minimize'?{}:{}"
      :cancel-text="cancelText"
      :title="title">
    <div slot="header" class="modalHeader" v-if="maximizeEnable">
      <div class="title">{{ title }}</div>
      <div class="operation">
<!--        <Icon type="md-remove" title="最小化" v-show="windowSize!=='minimize'" @click="changeMaximize('minimize')"/>-->
        <Icon type="ios-photos-outline" title="常规化" v-show="windowSize!=='regular'" @click="changeMaximize('regular')"/>
        <Icon type="md-square-outline" title="最大化" v-show="windowSize!=='maximize'" @click="changeMaximize('maximize')"/>
      </div>
    </div>
    <div class="modalMain">
      <Spin size="large" fix v-if="spinShow">
        <div style="padding: 30px 0;font-size: 14px">
          <Icon type="ios-loading" size=30 class="spin-icon-load"></Icon>
          <div style="padding-top: 10px;">加载中，请稍后...</div>
        </div>
      </Spin>
      <Spin size="large" fix v-if="initLoading">
        <div style="padding: 30px 0;font-size: 14px">
          <Icon type="ios-loading" size=30 class="spin-icon-load"></Icon>
          <div style="padding-top: 10px;">初始化数据，请稍后...</div>
        </div>
      </Spin>
      <template v-if="delayModShow">
        <slot v-if="windowSize!=='minimize'">
          <!--暂无用-->
          <slot name="main"></slot>
          <!--暂无用-->
          <slot name="bottomBtn"></slot>
        </slot>
      </template>
    </div>
  </Modal>
</template>
<script>


/**
 * 基于 iview 的支持双向绑定的自定义弹窗组件
 */
export default {
  name: 'ModalTemp',
  props: {
    zIndex: {type: Number, default: 1000},
    value: {type: Boolean, default: false},
    title: {type: String, default: '新增/编辑'},
    okText: {type: String, default: '确定'},
    cancelText: {type: String, default: '取消'},
    mask: {type: Boolean, default: true},
    footerHide: {type: Boolean, default: true},
    maximizeEnable: {type: Boolean, default: true},
    closable: {type: Boolean, default: true},
    draggable: {type: Boolean, default: false},
    fullscreen: {type: Boolean, default: false},
    maskClosable: {type: Boolean, default: false},
    transfer: {type: Boolean, default: true},
    width: {type: null, default: 400},
  },
  data() {
    return {
      fileType: {
        AVI: require('../../assets/images/AVI.png'),
        CDR: require('../../assets/images/CDR.png'),
        CSS: require('../../assets/images/CSS.png'),
        DOC: require('../../assets/images/DOC.png'),
        DOCX: require('../../assets/images/DOCX.png'),
        PDF: require('../../assets/images/PDF.png'),
        MOV: require('../../assets/images/MOV.png'),
        HTML: require('../../assets/images/HTML.png'),
        MP3: require('../../assets/images/MP3.png'),
        MP4: require('../../assets/images/MP4.png'),
        PHP: require('../../assets/images/PHP.png'),
        PPT: require('../../assets/images/PPT.png'),
        EPS: require('../../assets/images/EPS.png'),
        PSD: require('../../assets/images/PSD.png'),
        RAR: require('../../assets/images/RAR.png'),
        TTF: require('../../assets/images/TTF.png'),
        TXT: require('../../assets/images/TXT.png'),
        XLS: require('../../assets/images/XLS.png'),
        XLSX: require('../../assets/images/XLSX.png'),
        ZIP: require('../../assets/images/ZIP.png'),
      },
      initLoading: false,
      modShow: false,
      windowSize: 'regular',//maximize,minimize,regular
      delayModShow: false,
      spinShow: true,
      btnAreaTargetFlag: false,//判断弹窗是否有底部按钮区域
    }
  },

  watch: {
    delayModShow: function (val) {
      let timer = null;
      if (val && this.$slots.default) {
        timer = window.setInterval(() => {
          if (this.$slots.default && this.$slots.default[0].child) {
            this.spinShow = false;
            window.clearInterval(timer);
            this.btnAreaTargetFlag = !!document.querySelector('.modalEditBtnArea');
          }
        })
      }
    },
    value: function (val) {
      if (val && this.modShow && this.maximizeEnable && this.windowSize === 'minimize') {
        this.windowSize = 'regular'
      }
      if (val === this.modShow) return false;
      this.modShow = val;
    },
    modShow: function (val) {
      if (val) {
        this.spinShow = true;
        window.setTimeout(() => {
          this.delayModShow = true;
        }, 0);
      } else {
        window.setTimeout(() => {
          this.delayModShow = false;
        }, 500)
      }
      this.$emit('input', val)
    }
  },
  mounted() {
  },
  methods: {
    changeMaximize(value) {
      this.windowSize = value;
      this.$emit('changeMaximize', value)
    },
  },
}
</script>
<style lang="less" scoped>
.modalHeader {
  display: flex;
  padding-right: 10px;
  align-items: center;

  .title {
    flex: 1;
  }

  .operation {
    padding: 4px 8px;
    margin-top: -2px;
    cursor: pointer;

    .ivu-icon {
      margin: 0 10px;
    }
  }
}
</style>

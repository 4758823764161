<template>
  <div>
    <template v-for="(item) in menuList.filter(item=>!item.meta.hideInMenu)">
      <Dropdown :class="menuCls" placement="right-start" :style="{display:topMenu?'block':'inline-block'}" v-if="topMenu||(item.children&&item.children.length>0)" :key="item.name">
        <router-link :to="item.path" active-class="active" v-if="topMenu">
          <div class="menuList">
            <Icon :type="item.meta.icon||'md-apps'" size="30"/>
            <p style="line-height: 30px;">{{ item.meta.title }}</p>
          </div>
        </router-link>
        <DropdownItem v-else>
          <Icon :type="item.meta.icon||'md-apps'" size="16"/>
          {{ item.meta.title }}
          <Icon type="ios-arrow-forward"></Icon>
        </DropdownItem>
        <DropdownMenu :class="downMenuCls" slot="list" v-if="Array.isArray(item.children)&&item.children.filter(item=>!item.meta.hideInMenu).length>0">
          <ys-drop-down :menuList="item.children"></ys-drop-down>
        </DropdownMenu>
      </Dropdown>
      <DropdownItem :key="item.name" v-else>
        <router-link :to="item.path">
          <div>
            <Icon :type="item.meta.icon||'md-apps'" size="16"/>
            {{ item.meta.title }}
          </div>
        </router-link>
      </DropdownItem>
    </template>
  </div>
</template>

<script>
const prefixCls = 'ys-container-demo';

export default {
  name: "ys-drop-down",
  computed: {
    classes() {
      return prefixCls
    },
    menuCls() {
      return [this.classes + '-menu ', this.classes + '-menu-' + this.theme]
    },
    downMenuCls() {
      return [this.classes + '-down-menu ', this.classes + '-down-menu-' + this.theme]
    },
    currentOpenNames() {
      return this.openNames
    }
  },
  props: {
    topMenu: {type: Boolean, default: false},
    theme: {type: String, default: 'dark'},
    openNames: {type: Array, default: () => []},
    menuList: {type: Array, default: () => []}
  },
}
</script>

<style lang="less">

</style>
<template>
  <div>
    <template v-for="(item) in menuList.filter(item=>!item.meta.hideInMenu)">
      <Submenu :name="item.name" v-if="Array.isArray(item.children)&&item.children.length>0" :key="item.name">
        <template slot="title">
          <Icon :type="item.meta.icon||'md-apps'" size="16"/>
          <span>{{ item.meta.title }}</span>
        </template>
        <ys_menu :menu-list="item.children" v-show="!collapse"></ys_menu>
      </Submenu>
      <template v-else>
        <MenuItem :name="item.name" :to="item.path" :key="item.name">
          <Icon :type="item.meta.icon||'md-apps'" size="16"/>
          <span>{{ item.meta.title }}</span>
        </MenuItem>
      </template>
    </template>
  </div>
</template>

<script>

export default {
  name: "ys_menu",
  props: {
    collapse: {type: Boolean, default:false},
    menuList: {type: Array, default: () => []}
  },
}
</script>

<style  lang="less">

</style>
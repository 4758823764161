<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/resource/GetFileList"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="附件类型" prop="type">
          <Select v-model="params.type" transfer clearable placeholder="附件类型" style="width: 150px;">
            <Option v-for="(item,index) in typeObj[resourceType]" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add" v-if="powerType===1">新增附件</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1000" title="新增/修改附件">
      <Edit :typeObj="typeObj[resourceType]" :resourceType="resourceType" :modData="tableSelectItem" :resourceId="mid" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
export default {
  name: 'resource',
  props: {
    powerType: {type: Number, default: 1,},
    resourceType: {type: Number, default: 1, required: true},
    mid: {type: Number, default: null, required: true},
    tableHeight: {type: [Number, String], default: null},
  },
  components: {
    'ysPageList': () => import('../../components/ys-page-list'),
    'Edit': () => import('./edit.vue'),
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.powerType !== 1
            },
            {
              click: () => this.delItem('/resource/file/Remove', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length === 0 || this.powerType !== 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.downFile(),
              title: '下载',
              icon: 'md-download',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
    typeObj() {
      return {
        1: window.baseData['附件类型'].filter(item => item.value >= 100 && item.value < 200),
        2: window.baseData['附件类型'].filter(item => [201, 207, 208,401, 404, 405, 406, 407, 408, 409].indexOf(item.value) >= 0),
        3: window.baseData['附件类型'].filter(item => [301, 302, 303, 304].indexOf(item.value) >= 0),
        4: window.baseData['附件类型'].filter(item => [202, 203].indexOf(item.value) >= 0),
        5: window.baseData['附件类型'].filter(item => [204].indexOf(item.value) >= 0),
        6: window.baseData['附件类型'].filter(item => [205].indexOf(item.value) >= 0),
        7: [],
        8: window.baseData['附件类型'].filter(item => [401, 402, 403, 404, 405, 406, 407, 408, 409].indexOf(item.value) >= 0),
        9: window.baseData['附件类型'].filter(item => [501, 502].indexOf(item.value) >= 0),
        10: window.baseData['附件类型'].filter(item => [601].indexOf(item.value) >= 0),
        11: window.baseData['附件类型'].filter(item => [701].indexOf(item.value) >= 0),
        12: window.baseData['附件类型'].filter(item => [1201, 1202].indexOf(item.value) >= 0),
        20: window.baseData['附件类型'].filter(item => [2001].indexOf(item.value) >= 0),
        22: window.baseData['附件类型'].filter(item => [2201,2202].indexOf(item.value) >= 0),
        23: window.baseData['附件类型'].filter(item => [2301].indexOf(item.value) >= 0),
        24: window.baseData['附件类型'].filter(item => [2401].indexOf(item.value) >= 0),
      }
    },
  },
  data() {
    return {
      tableSelectItem: [],
      modShow: false,
      params: {
        resourceType: '',
        type: '',
        resourceId: ''
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {width: 71, title: '附件', key: 'id', align: 'center', renderConfig: {type: 'image'}},
        {minWidth: 250, title: '附件名称', key: 'fileName', align: 'left'},
        {width: 90, title: '资源类型', key: 'resourceType', align: 'left', renderConfig: {type: 'baseData', parentName: '资源类型'}},
        {width: 120, title: '附件类型', key: 'type', align: 'left', renderConfig: {type: 'baseData', parentName: '附件类型'}},
        {width: 80, title: '附件大小', key: 'size', align: 'left'},
        {width: 180, title: '创建时间', key: 'createdAt', align: 'left'},
        {minWidth: 250, title: '描述', key: 'desc', align: 'left'},
      ]
    }
  },
  mounted() {
    this.params.resourceId = this.mid;
    this.params.resourceType = this.resourceType;
    window.setTimeout(() => {
      this.getList()
    }, 300)
  },
  methods: {
    downFile() {
      const a = document.createElement('a')
      a.download = this.tableSelectItem[0].fileName
      a.href = this.$axiosBaseUrl + '/resource/GetFile/' + this.tableSelectItem[0].id;
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    getList(nextType) {
      this.tableSelectItem = [];
      this.$refs['table'] ? this.$refs['table'].selectAll(false) : '';
      this.$refs['table'].getList(nextType);
    },
    add() {
      this.modShow = true;
      this.tableSelectItem = [];
      this.$refs['table'].selectAll(false);
    },
    checkItem(selection) {
      this.tableSelectItem = selection;
    },
    delItem(url, params, method = '$del') {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要删除这 ' + this.tableSelectItem.length + ' 条记录么?一旦删除将无法恢复!',
        okText: '删除',
        cancelText: '取消',
        onOk: () => {
          this[method](url, params ? params : {id: this.tableSelectItem[0].id}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    }
  }
}
</script>

<template>
  <DatePicker
      :type="type"
      :value="value"
      :editable="editable"
      @on-change="dateChange"
      transfer
      :style="{width:width}"
      :size="size"
      :separator="separator"
      :clearable="clearable"
      :placement="placement"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="optionsFn"
  ></DatePicker>
</template>

<script>
/**
 *
 * 基于 iview 的支持双向绑定的时间选择器
 */
import {clone, formatDate} from '../../lib/index';

export default {
  name: 'DatePickerTemp',
  props: {
    type: {type: String, default: 'date'},
    separator: {type: String, default: ' 至 '},
    size: {type: String, default: 'default'},
    options: {type: Object, default: () => null},
    placeholder: {type: String, default: ''},
    placement: {type: String, default: 'bottom-start'},
    value: {type: [String, Number, Array, Date], default: null},
    disabled: {type: Boolean, default: false},
    editable: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    disabledDate: {type: Function}
  },
  computed: {
    width: function () {
      let width = '120px'
      switch (this.type) {
        case 'datetimerange':
          width = '340px';
          break;
        case 'daterange':
          width = '220px';
          break;
        case 'date':
          width = '120px';
          break;
        case 'datetime':
          width = '180px';
          break;
        case 'month':
          width = '100px';
          break;
        case 'year':
          width = '80px';
          break;
      }
      return width
    },
    optionsFn() {
      let options = {}
      if(this.type !== 'daterange' && this.type !== 'datetimerange') options = this.dataOptions
      // 如存在不可选日期则注入且将左侧快捷选项菜单置空(因为可能会存在与不可选日期冲突)
      if(this.disabledDate) {
        options.shortcuts = []
        options.disabledDate = this.disabledDate
      }
      return options
    }
  },
  data() {
    return {
      dataOptions: {},
    }
  },
  watch: {
    value: function (data) {
      if (this.type !== 'daterange') {
        this.format(data);
      }
    }
  },
  mounted() {
    if (this.value && Object.prototype.toString.call(this.value).slice(8, -1) === 'Date') {
      this.format(this.value);
    }
    if (this.options) {
      this.dataOptions = clone(this.options);
    } else {
      this.dataOptions = {
        shortcuts: [
          {
            text: '今天',
            value() {
              return new Date();
            },
          },
          {
            text: '昨天',
            value() {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              return date;
            },
          },
          {
            text: '一周后',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              return date;
            },
          },
          {
            text: '一年后',
            value() {
              const date = new Date();
              date.setFullYear(date.getFullYear() + 1);
              return date;
            },
          },
          {
            text: '两年后',
            value() {
              const date = new Date();
              date.setFullYear(date.getFullYear() + 2);
              return date;
            },
          }
        ]
      }
    }
  },
  methods: {
    format(dateValue) {
      if (this.type === 'daterange') {
        this.dateChange(dateValue);
      } else if (this.type === 'datetimerange') {
        this.dateChange(dateValue);
      } else {
        let formatType = 'yyyy-MM-dd';
        switch (this.type) {
          case 'date':
            formatType = 'yyyy-MM-dd';
            break;
          case 'datetime':
            formatType = 'yyyy-MM-dd hh:mm:ss';
            break;
          case 'month':
            formatType = 'yyyy-MM';
            break;
          case 'year':
            formatType = 'yyyy';
            break;
        }
        this.dateChange(dateValue ? formatDate(new Date(dateValue), formatType) : '');
      }
    },
    dateChange(date) {
      this.$emit('input', date);
      this.$emit('on-change', date);
    },
  }
}
</script>

<style type="text/css">
@media print {
  .ys-paper {
    border-color: transparent !important;
  }
}
</style>
<template>
  <div :class="classCls">
    <div :class="cornerCls" class="leftTop"></div>
    <div :class="cornerCls" class="rightTop"></div>
    <div :class="cornerCls" class="leftBottom"></div>
    <div :class="cornerCls" class="rightBottom"></div>
    <div :class="mainCls">
      <slot></slot>
    </div>
  </div>
</template>

<script>
const prefixCls = 'ys-paper';

export default {
  name: "ys-paper",
  props: {
    direction: {
      type: String,
      default: 'horizontal'/*vertical and horizontal*/
    }
  },
  computed: {
    classCls() {
      return [`${prefixCls}`, `${prefixCls}-${this.direction}`]
    },
    mainCls() {
      return [`${prefixCls}-${this.direction}-main`]
    },
    cornerCls() {
      return [`${prefixCls}-corner`]
    },
  }
}
</script>
<style lang="less" scoped>

</style>

<template>
  <div>
    <Form ref="form" class="editForm " :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <h1 class="h1Title">综合得分</h1>
      <FormItem label="" :label-width="0" la prop="fileList">
        <RadioGroup v-model="form.score">
          <Radio :label="l.max" border v-for="(l,index) in levels" :key="index">{{ l.name }}</Radio>
        </RadioGroup>
      </FormItem>
      <h1 class="h1Title">各类评分</h1>
      <div v-for="(item,index) in this.form.subExamines" :key="index" class="score">
        <p>{{ item.name }}</p>
        <p class="desc">{{ item.content }}</p>
        <FormItem label="" :label-width="0" la prop="fileList">
          <RadioGroup v-model="item.score">
            <Radio :label="l.max" border v-for="(l,index) in levels" :key="index">{{ l.name }}</Radio>
          </RadioGroup>
        </FormItem>
      </div>
      <h1 class="h1Title">评分说明</h1>
      <FormItem label="评分说明" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 2,maxRows: 3}" placeholder="回复描述"></Input>
      </FormItem>
      <FormItem label="附件" prop="fileList">
        <ysUpload @on-success="getFile" :limit="1" :format="['jpg','jpeg','png','gif']" :headers="{token:token}" action="/web/resource/UploadFile"></ysUpload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">保存并发送</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>

import edit from "./edit";
import {examineList, getSubsAndLevelOptsById} from "./examine";
import {formatDate, uuid} from '../../lib/index'

export default {
  name: 'examineScore',
  mixins: [edit],
  props: {
    target: {
      type: Object, default: () => {
        return {}
      },
      required: true
    }
  },
  data() {
    return {
      ruleValidate: {
        status: [
          {required: true, type: 'number', message: '请填选择处理状态', trigger: 'change'}
        ],
      },
      levels: [],
      form: {
        id: '',
        code: '',
        configId: '',
        score: '',
        desc: '',
        fileId: '',
        originId: '',
        originType: '',
        createdUser: '',
        targetId: '',
        targetType: '',
        originName: '', // 考核机构或人员名称
        frequent: '', // 考评频次类型
        subExamines: [],
        subExamineItems: [],
      },
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.form.targetName = this.target.targetName;
      this.form.originType = this.target.originType;
      this.form.originId = this.userInfo.org_id;
      this.form.createdUser = this.userInfo.userName;
      this.form.code = uuid();

      this.form.targetId = this.target.targetId;
      this.form.targetType = this.target.targetType;
      this.form.originName = this.target.originName;
      this.form.frequent = this.target.frequent;

      this.form.timeAt = formatDate(new Date());
      await examineList({originType: this.target.originType,targetType: this.target.targetType, frequent: this.target?.frequent || ''}).then(res => {
        if (res.code === 200 && res.data.list) {
         this.form.configId = res.data.list[0].id;
         this.form.name = res.data.list[0].name+'综合得分';
        }
      })
      await this.getDetail();
    },
    getDetail() {
      this.form.subExamines = [];
      getSubsAndLevelOptsById({id: this.form.configId}).then(res => {
        if (res.code === 200) {
          this.levels = res.data.Levels || [];
          let items = res.data.SubConfigs || [];
          this.form.score = this.levels[0]?.max;
          this.target.list.forEach(item => {
            items.forEach(subItem => {
              if (subItem.targetType === item.targetType) {
                this.form.subExamines.push({
                  id: '',
                  name: subItem.name,
                  content: subItem.desc,
                  createdUser: this.userInfo.userName,
                  code: uuid(),
                  configId: subItem.id,
                  score: this.levels[0]?.max,
                  originId: this.userInfo.org_id,
                  originType: this.target.originType,
                  targetId: item.targetId,
                  targetType: item.targetType,
                  targetName: item.targetName,
                  originName: this.target.originName,
                  timeAt: formatDate(new Date()),
                });
              }
            })
          })
        }
      })
    },
    save() {
      this.ok('orgExamine/Create');
    },
    getFile(data) {
      this.form.fileId = data[0].id;
    },
  }
}
</script>

<style lang="less" scoped>
.score {
  padding: 8px 10px 0 10px;
  border: 1px solid #ececec;
  margin-bottom: 10px;
  background-color: #fafafa;

  .desc {
    padding: 3px 0 10px 0;
    font-size: 12px;
    color: #cccccc;
  }
}
</style>

<template>
  <div :class="classes" :style="{width: block?'100%':''}">
    <div :class="iconCls" v-if="icon">
      <Icon :type="icon" :size="18" style="vertical-align: middle;margin-top: -2px;"/>
    </div>
    <div :class="mainCls" :style="{flexDirection: inline!==null ? (inline===true?'row':'column'):''}">
      <div :class="mainTitleCls">{{ title + (quote ? '：' : '') }}</div>
      <div :class="mainLabelCls" ref="contentBox" :title="block?'':label" :style="{maxHeight:block?'initial':(inline?'21px':'21px')}">
        <Tooltip :content="label" :max-width="300" placement="top" v-if="showTooltip">
          <div ref="content" :class="mainLabelTooltipCls" v-html="label"></div>
        </Tooltip>
        <div ref="content" v-else v-html="label"></div>
      </div>
    </div>
    <div :class="mainValueCls">
      <div :class="mainValueCls" :title="value" v-if="!$slots.value">{{ value }}</div>
      <slot v-else name="value"></slot>
    </div>
    <div :class="footerCls">
      <div :class="extraCls">{{ extra }}</div>
      <div :class="footerIconCls" v-if="isLink||to">
        <Icon type="ios-arrow-forward" size="20" style="vertical-align: middle;margin-top: -2px;"/>
      </div>
    </div>
  </div>
</template>

<script>
const prefixCls = 'ys-cell';

export default {
  name: "ys-cell",
  props: {
    icon: {type: String, default: null},/*'ios-analytics'*/
    quote: {type: Boolean, default: true},
    block: {type: Boolean, default: false},

    isLink: {type: Boolean, default: false},
    inline: {type: Boolean, default: null},
    extra: {type: String, default: null},
    title: {type: [String, Number], default: null},
    label: {type: [String, Number], default: null},
    to: {type: String, default: null},


    value: {type: [String, Number], default: null},
    name: {type: [String, Number], default: null},
    disabled: {type: Boolean, default: false},
    selected: {type: Boolean, default: false},
    replace: {type: Boolean, default: false},
    target: {type: String, default: null},
    append: {type: Boolean, default: true},
  },
  computed: {
    classes() {
      return prefixCls
    },
    iconCls() {
      return prefixCls + '-icon'
    },
    mainCls() {
      return prefixCls + '-main'
    },
    mainTitleCls() {
      return this.mainCls + '-title'
    },
    mainLabelCls() {
      return [this.mainCls + '-label', this.block ? this.mainCls + '-label-block' : '']
    },
    mainLabelTooltipCls() {
      return [this.mainCls + '-label' + (this.showTooltip ? '-tooltip' : '')]
    },
    mainValueCls() {
      return [this.mainCls + '-value']
    },
    footerCls() {
      return prefixCls + '-footer'
    },
    extraCls() {
      return this.footerCls + '-extra'
    },
    footerIconCls() {
      return this.footerCls + '-icon'
    },

  },
  data() {
    return {
      showTooltip: false
    }
  },

  watch:{
    label:function (data){
      if(!this.block&&data){
        this.$nextTick(()=>{
          this.handleTooltipIn()
        })
      }
    }
  },
  methods: {
    handleTooltipIn() {
      if (this.showTooltip) return;
      const $content = this.$refs.content;
      const $contentBox = this.$refs.contentBox;
      if (!$content || !$contentBox) return;
      this.showTooltip = $content.offsetHeight > $contentBox.offsetHeight
    },
  }
}
</script>

<style scoped>

</style>
import Vue from 'vue'
import Vuex from 'vuex'
import {clone, get, listToTree} from "ys-admin";
import config from "@/config";
import router from "@/router";
import {resetRouter} from "@/router";
import {azxDefaultRouters, middleOfficeDefaultRouters, IDIDefaultRouters} from '@/store/routerList'


Vue.use(Vuex)

/**
 * deepRouterMap 处理接口返回的路由数据
 * @routerMap {array}
 * @return {array} 处理后的数组
 **/
const deepRouterMap = (routerMap = []) => {
  return routerMap.map((item) => {
    item.meta = {title: item.title, home: item.home, hideInMenu: item.hideInMenu ? true : false, index: item.index, icon: item.icon};
    if (!Array.isArray(item.children) || item.children.length === 0) {
      if (item.file) item.component = (resolve) => require(['@/views/' + item.file], resolve);
    } else {
      item.component = (resolve) => require(['@/views/routerContainer'], resolve);
      deepRouterMap(item.children);
    }
    return item
  })
}
export default new Vuex.Store({
  state: {
    menuList: [],
    config: sessionStorage.getItem('config') ? JSON.parse(String(sessionStorage.getItem('config'))) : {},
    height: null,
    userInfo: sessionStorage.getItem('userInfo') ? JSON.parse(String(sessionStorage.getItem('userInfo'))) : {},
    token: String(sessionStorage.getItem('token') || ''),
    baseData: {}, /*基础数据项，调用方式this.baseData['项目类型']*/
    topMenuActive: '1', // 平类类型，1：安责险，2：中台，3：IDI平台
  },
  mutations: {
    setHeight(states, data) {
      states.height = data;
    },
    setConfig(states, data) {
      states.config = {...states.config, ...data};
      sessionStorage.setItem('config', JSON.stringify(states.config));
    },
    setMenuList(states, data) {
      resetRouter();
      if (data.length > 0) {
        const menuList = listToTree(data, {parentKey: 'parentId', topLevelVal: 0});
        deepRouterMap(clone(menuList)).forEach(item => {
          router.addRoute(config.routerParent, item);
        })
        router.addRoute(config.routerParent, {path: '/main/*', name: '404', meta: {icon: 'ios-appstore', title: '404', index: '99', hideInMenu: true}, component: () => import('../components/404')})
        /*追加404页面路由*/
      } else {/*接口未返回路由数据则启用默认路由，看前面代码*/
        switch (states.topMenuActive) {
          case '1':
            azxDefaultRouters.forEach(item => {
              router.addRoute(config.routerParent, item);
            })
            break;
          case '2':
            middleOfficeDefaultRouters.forEach(item => {
              router.addRoute(config.routerParent, item);
            })
            break;
          case '3':
            IDIDefaultRouters.forEach(item => {
              router.addRoute(config.routerParent, item);
            })
            break;
        }
      }
      let routes = clone(router.getRoutes()).map(({...item}) => {
        item.parentName = item.parent ? item.parent.name : '0'
        return item
      });
      states.menuList = listToTree(routes.filter(item => !item.meta.hideInMenu), {parentKey: 'parentName', sonKey: 'name', topLevelVal: '0'}).filter(item => item.name === config.routerParent)[0].children;
    },
    setToken(states, data) {
      sessionStorage.setItem('token', data);
      states.token = data;
    },
    setUserInfo(states, data) {
      let userInfo = {};
      if (data != undefined) {
        userInfo = JSON.parse(JSON.stringify(data));
        if (userInfo.orgs && userInfo.orgs.length >= 1) {
          userInfo.org_id = userInfo.orgs[0].id;
        }
      }
      sessionStorage.setItem('orgId', userInfo.org_id || 1);
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      states.userInfo = userInfo;
    },
    setBaseData(states, data) {
      states.baseData = data;
      let baseDataMap = new Map();
      Object.keys(data || {}).forEach(item => {
        baseDataMap.set(item, data[item])
      })
      states.baseData = data;
      window.baseData = baseDataMap;
    },
    setTopMuanActive(states, data) {
      states.topMenuActive = data;
    },
  },
  getters: {
    menuList: (state) => state.menuList,
    config: (state) => state.config,
    height: (state) => state.height,
    token: (state) => state.token,
    baseData: (state) => state.baseData,
    userInfo: (state) => state.userInfo,
    topMenuActive: (state) => state.topMenuActive,
  },
  actions: {
    getMenuList({commit, getters}) {
      switch (getters.topMenuActive) {
        case '1':
          return new Promise((resolve, reject) => {
            get("/menu/OrgMenuList", {o_type: 6}).then((res) => {
              if (res.code === 200) {
                commit('setMenuList', res.data || []);
                resolve();
              } else {
                commit('setMenuList', []);
                reject();
              }
            })
          })
        case '2':
          commit('setMenuList', []);
          break;
        case '3':
          commit('setMenuList', []);
          break;
      }
    },
  }
})

<template>
  <transition name="fade">
    <div :class="classCls">
      <slot></slot>
    </div>
  </transition>
</template>

<script lang="ts">
const prefixCls = 'ys-cell-menu-group';

export default {
  name: 'ys-cell-menu-group',
  computed: {
    classCls() {
      return prefixCls
    }
  }
}
</script>

<style scoped lang="less">

</style>

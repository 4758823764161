<template>
  <div :class="classes">
    <ys-header :collapsePosition="config.collapsePosition" :headerConfig="headerConfig" @on-toggle="setCollapse" v-if="headerConfig.type==='top'&&headerConfig.show">
      <template v-if="config.headerType==='top'">
        <slot name="logo" v-if="$slots.logo"></slot>
        <ys-logo :theme="config.menuTheme" :collapse="config.collapse" v-else></ys-logo>
      </template>
      <slot name="header"></slot>
    </ys-header>
    <div :class="classes+'-main'" :style="mainStyles">
      <div :class="classes+'-main-left '+classes+'-main-left-'+(config.menuTheme||'dark')" :style="leftStyles" v-if="leftConfig.show">
        <template v-if="config.headerType==='inner'">
          <slot name="logo" v-if="$slots.logo"></slot>
          <ys-logo :theme="config.menuTheme" :collapse="config.collapse" v-else></ys-logo>
        </template>
        <slot name="menu" v-if="$slots.menu"></slot>
        <ys-menu :menuList="menuList" :menuMountByRouterName="leftConfig.menuMountByRouterName" :accordion="config.accordion" :collapse="config.collapse" :theme="config.menuTheme" v-else style="overflow-y: auto" :style="menuStyle"></ys-menu>
        <div :class="collapseCls" @click="setCollapse" v-if="config.collapsePosition==='left'" :style="leftCollapseCls">
          <Icon :class="rotateIcon" type="md-menu" size="24"></Icon>
        </div>
      </div>
      <div :class="classes+'-main-right'" :style="rightStyles">
        <ys-header :collapsePosition="config.collapsePosition" :headerConfig="headerConfig" @on-toggle="setCollapse" v-if="headerConfig.type==='inner'&&headerConfig.show">
          <slot name="header"></slot>
        </ys-header>
        <ys-tag/>
        <div :class="classes+'-main-right-container'">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {clone} from "../../lib/index";
import ysLogo from './demo/Logo.vue'
import ysMenu from './demo/Menu.vue'
import ysTag from './demo/Tag.vue'
import bus from "../../bus";
import ysHeader from './demo/header.vue'

const prefixCls = 'ys-container';
const leftDefault = {
  show: true,
  menuMountByRouterName: 'main',
  collapseWidth: '80px',
  styles: {width: '220px'}
}
const mainDefault = {
  styles: {paddingTop: '0', width: '100%',},
}
const headerDefault = {
  show: true,
  styles: {height: '55px'},
  type: 'top',//可选top inner
}

export default {
  name: "ys-container",
  components: {
    ysTag,
    ysHeader,
    ysLogo,
    ysMenu,
  },
  data() {
    return {
      config: localStorage.getItem('set') ? JSON.parse(localStorage.getItem('set')) : {},
      leftConfig: {},
    }
  },
  props: {
    menuList: {
      type: Array,
      default: () => []
    },
    leftLayout: {
      menuMountByRouterName: {type: String, default: 'main'},
      type: Object, default: () => {
        return leftDefault
      }
    },
    maxScreenHeight: {type: Boolean, default: false},
    headerLayout: {
      type: Object, default: () => {
        return headerDefault
      }
    },
    mainLayout: {
      type: Object, default: () => {
        return mainDefault
      }
    },
  },
  computed: {
    rightStyles() {
      if (this.config.scroll) {
        return {height: 'auto'}
      } else {
        return {height: this.config.headerType === 'top' ? 'calc(100vh - 55px)' : '100vh', overflow: 'hidden'}
      }
    },
    menuStyle() {
      if (this.config.collapsePosition === 'left') {
        return {height: 'calc(100vh - 55px)'}
      } else {
        return {height: this.config.headerType === 'top' ? 'calc(100vh - 110px)' : 'calc(100vh - 55px)'}
      }
    },
    rotateIcon() {
      return [
        this.config.collapse ? 'rotate-icon' : ''
      ];
    },
    leftCollapseCls() {
      let width = this.config.width || 100;
      return {
        left: 'calc(' + (100 - width) / 2 + '% + 20px)'
      }
    },
    headerConfig() {
      let config = this.initLayout(headerDefault, this.headerLayout);
      config.type = this.config.headerType || 'inner';
      return config;
    },
    mainConfig() {
      let config = this.initLayout(mainDefault, this.mainLayout);
      config.styles.width = (this.config.width || 100) + '%';
      return config;
    },
    leftStyles() {
      let styles = clone(this.leftConfig.styles);
      if (this.config.collapse) {
        styles.width = this.leftConfig.collapseWidth
      } else {
        styles.width = this.config.menuWidth + 'px';
      }
      return styles
    },
    classes() {
      return prefixCls
    },
    collapseCls() {
      return [prefixCls + '-collapse', prefixCls + '-collapse-' + (this.config.menuTheme || 'dark'), prefixCls + '-collapse-' + this.config.collapsePosition]
    },
    headerCollapseCls() {
      return [prefixCls + '-header-collapse',]
    },
    mainStyles() {
      let styles = clone(this.mainConfig.styles);
      let height;
      switch (this.headerConfig.type) {
        case 'top':
          height = 'calc(100vh - 110px)';
          break;
        case 'inner':
          height = '100vh'
          break;
        default:
          height = 'auto'//预留用
          break;
      }
      if (!this.headerConfig.show) height = '100vh'
      if (this.maxScreenHeight) {
        styles.height = height;
      } else {
        styles.minHeight = height;
      }
      return styles;
    },
  },
  created() {
    bus.$on('set', (data) => {
      this.config = data;
    })
  },
  mounted() {
    this.leftConfig = this.initLayout(leftDefault, this.leftLayout)
  },

  methods: {
    setCollapse() {
      this.config.collapse = !this.config.collapse;
      bus.$emit('set', this.config);
      localStorage.setItem('set', JSON.stringify(this.config))
    },
    initLayout(init, prop) {
      let config = clone(init)
      Object.keys(init).forEach(item => {
        config[item] = prop[item] === undefined ? init[item] : prop[item];
      })
      return config
    }
  }
}
</script>


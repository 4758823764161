<template>
  <div>
    <Affix :key="key">
      <header :class="headerCls" :style="headerConfig.styles">
        <div :class="headerCollapseCls" @click="setCollapse" v-if="collapsePosition==='header'">
          <Icon :class="rotateIcon" type="md-menu" size="30"></Icon>
        </div>
        <slot></slot>
        <div @click="drawerFlag=true" :class="`${classes}-set`" size="30">
          <Icon size="25" type="ios-settings"/>
        </div>
      </header>
    </Affix>
    <Drawer mask :width="350" v-model="drawerFlag">
      <ys-set></ys-set>
    </Drawer>
  </div>
</template>

<script>
import bus from "../../../bus";
import YsSet from "../set";

const prefixCls = 'ys-container-header';

export default {
  name: "ys-header",
  components: {YsSet},
  props: {
    collapsePosition: {type: String, default: null},
    headerConfig: {
      type: Object, default: () => {
      }
    },
  },
  data() {
    return {
      key: 1,
      drawerFlag: false,
      config: localStorage.getItem('set') ? JSON.parse(localStorage.getItem('set')) : {},
    }
  },
  computed: {
    classes() {
      return prefixCls
    },
    headerCls() {
      return [`${prefixCls}`, `${prefixCls}-` + this.headerConfig.type]
    },
    headerCollapseCls() {
      return [prefixCls + '-collapse',]
    },
    rotateIcon() {
      return [
        this.config.collapse ? 'rotate-icon' : ''
      ];
    },
  },
  created() {
    bus.$on('set', (data) => {
      this.config = data
    })
  },

  methods: {
    setCollapse() {
      this.$emit('on-toggle');
      window.setTimeout(() => {
        this.key = new Date().getTime();
      }, 100)
    },
  },

}
</script>

<style scoped>

</style>
<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="100">
      <FormItem label="原密码" prop="Pass" v-if="!mid">
        <Input type="password" v-model="form.Pass" placeholder="请输入原密码"></Input>
      </FormItem>
      <FormItem label="新密码" prop="New_pass">
        <Input type="password" v-model="form.New_pass" placeholder="请输入新密码"></Input>
      </FormItem>
      <FormItem label="重复新密码" prop="Re_pass">
        <Input type="password" v-model="form.Re_pass" placeholder="请输入新密码"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(mid?'user/ChangePwd':'user/EditPwd')">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import editMixins from '@/mixins/edit'; //引入混入组件edit

export default {
  name: '',
  mixins: [editMixins],
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      ruleValidate: {
        Pass: [
          {required: true, message: '原密码不能为空！', trigger: 'blur'}
        ],
        New_pass: [
          {required: true, message: '新密码不能为空！', trigger: 'blur'}
        ],
        Re_pass: [
          {
            required: true, trigger: 'blur', validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码!'));
              } else if (value !== this.form.New_pass) {
                callback(new Error('两次密码输入不一致，请确认!'));
              } else {
                callback();
              }
            }
          },
        ]
      },
      form: {
        Id: '',
        New_pass: '',
        Pass: '',
        Re_pass: '',
      },
    }
  },

  mounted() {
    this.form.Id = this.mid || this.userInfo.id;
  },
  methods: {}
}
</script>

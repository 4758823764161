<template>
  <div :class="classCls">
    <template v-if="fileList.length>0">
    <ul class="fileList" v-show="fileList.length>1">
      <li v-for="(item,i) in fileList" :key="item.id" :class="{active:i===index}" @click="index=i" :title="item.fileName">{{ item.fileName }}</li>
    </ul>
    <div class="review">
      <iframe v-if="['pdf'].indexOf(suffix(fileList[index].fileName).toLowerCase())>=0" :src="fileList[index].url+'#toolbar=0'" frameborder="0" style="width: 100%;overflow-y: auto;height:calc(100vh - 190px)"></iframe>
      <iframe v-else-if="['doc','docx','xls','xlsx'].indexOf(suffix(fileList[index].fileName).toLowerCase())>=0" :src="'https://view.officeapps.live.com/op/view.aspx?src='+encodeURIComponent(fileList[index].url)+'#toolbar=0'" frameborder="0" style="width: 100%;overflow-y: auto;height:calc(100vh - 190px)"></iframe>
      <div v-else-if="['JPG', 'GIF', 'PNG', 'JPEG'].indexOf(suffix(fileList[index].fileName).toUpperCase())>=0" style="width: 100%;overflow-y: auto;height:calc(100vh - 190px)">
        <img alt="" :src="fileList[index].url" style="width: 100%"/>
      </div>
    </div>
    </template>
    <ys-empty v-else style="margin: 0 auto"></ys-empty>
  </div>
</template>

<script>
import download from "downloadjs"

const prefixCls = 'ys-file-review';

export default {
  name: "ys-file-review",
  props: {
    fileList: {type: Array, default: () => []},
  },
  computed: {
    classCls() {
      return prefixCls;
    },
    suffix() {
      return (fileName) => {
        let file = fileName.split('.');
        return file[file.length - 1];
      }
    },
  },
  data() {
    return {
      index: 0
    }
  },
  mounted() {

  },
  methods: {
    downFile(item) {
      const request = new XMLHttpRequest()
      request.open('GET', item.url)
      request.responseType = 'blob'
      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          download(request.response, item.fileName)
        }
      }
      request.send()
    },
  }
}
</script>

<style scoped lang="less">
.ys-file-review {
  width: 100%;
  display: flex;
  align-items: flex-start;

  .fileList {
    width: 300px;
    padding-right: 10px;

    li {
      padding: 5px 15px;
      border-radius: 3px;
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 5px;

      &.active, &:hover {
        background-color: #f4f4f4;
        cursor: pointer;
      }
    }
  }

  .review {
    flex: 1;
  }

}
</style>
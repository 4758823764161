import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'editMixins',
  props: {
    mid: {type: [String, Number], default: null},
    midData: {type: Array, default: () => []},
    parentID: {type: [String, Number], default: null},
    modData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {},
      stepNum: 1,
      spinLoading: true,
      modalLoading: true,
      ruleValidate: {},
      modal: null,
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'baseData', 'token', 'height', 'config'])
  },
  mounted() {
    this.modal = this.$parent.$parent;
  },
  methods: {
    ...mapActions(['getConfigInfo']),
    close() {
      this.modal.modShow = false;
    },
    ok(url, nextType) {
      this.modal.spinShow = true;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$post(url, this.form).then((res) => {
            this.modal.spinShow = false;
            if (res.code === 200) {
              this.close();
              this.$Notice.success({
                title: "提示！",
                desc: '操作成功！'
              });
              this.$emit('on-ok', res.data, nextType, this.form);
            }
          }).catch(() => {
            this.modal.spinShow = false;
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.modal.spinShow = false;
        }
      })
    }
  }
};

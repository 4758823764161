import {Notice} from 'view-design'
import axios from 'axios';

// 声明一个 Map 用于存储每个请求的标识 和 取消函数

const pending = new Map()
/**
 * 添加请求
 * @param {Object} config
 */
export const addPending = (config) => {
  if (!config.pendingFlag || (config.params && config.params['noPending'])) return;
  const url = [
    config.method,
    config.url,
    JSON.stringify(config.params),
    JSON.stringify(config.data)
  ].join('&')
  config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
    if (!pending.has(url)) { // 如果 pending 中不存在当前请求，则添加进去
      pending.set(url, cancel)
    }
  })
};

/**
 * 移除请求
 * @param {Object} config
 */
export const removePending = (config) => {
  const url = [
    config.method,
    config.url,
    JSON.stringify(config.params),
    JSON.stringify(config.data)
  ].join('&')
  if (pending.has(url)) { // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    const cancel = pending.get(url)
    cancel(url)
    pending.delete(url)
  }
};

export const errorHandle = (status, other, msg) => {
  let res = {success: false, message: msg, code: status};
  switch (parseInt(status)) {
    case 400:
      res.message =  res.message || `400：参数错误`;
      break;
    case 403:
    case 401:
      res.message = res.message || `403：登录过期，请重新登录`;
      sessionStorage.clear();
      window.location.href = axios['loginPath'] || '/login'
      break;
    case 404:
      res.message = res.message || `404：功能未开放或资源不存在${other}`;
      break;
    case 500:
      res.message = res.message || `500：服务器错误`;
      break;
    case 502:
      res.message = res.message || `502：网关错误`;
      break;
    case 504:
      res.message = res.message || `504：服务器超时`;
      break;
    default:
      res.message = res.message || `服务器响应错误，请稍后再试`;
      break
  }
  if (status !== 200) {
    Notice.error({
      title: '提示',
      desc: res.message || '网络异常，请稍后再试！'
    });
  }
};

/**
 * 清空 pending 中的请求（在路由跳转时调用）
 */
export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url)
  }
  pending.clear()
}
<template>
  <div class="topHeader" v-if="userInfo">
    <div class="avatar">
      <Poptip trigger="hover" placement="bottom-end">
        <div class="avatar-dropdown">
          <Avatar icon="ios-person"/>
          {{ userInfo.name }}
          <Icon type="ios-arrow-down"/>
        </div>
        <div class="userInfoCard" slot="content">
          <div class="userInfo">
            <Avatar icon="ios-person" size="large"/>
            <div class="info">
              <p class="name">{{ userInfo.name }}</p>
              <p class="brief">电话：{{ userInfo.phone }}</p>
              <p class="brief">身份证：{{ userInfo.identityCode }}</p>
            </div>
          </div>
          <div class="operation">
            <a class="item" href="javascript:;" @click="modFlag = true">
              <Icon type="ios-lock-outline"/>
              修改密码</a>
            <a class="item" href="javascript:;" @click="loginOut">
              <Icon type="ios-log-out"/>
              退出登录</a>
          </div>
        </div>
      </Poptip>
      <!--      <Dropdown @on-click="set">-->
      <!--        <div class="avatar-dropdown">-->
      <!--          <Avatar icon="ios-person"/>-->
      <!--          {{ userInfo.name }}-->
      <!--          <Icon type="ios-arrow-down"/>-->
      <!--        </div>-->
      <!--        <DropdownMenu slot="list">-->
      <!--          <DropdownItem name="modPass">-->
      <!--            <Icon type="ios-unlock" style="margin-top: -3px; vertical-align: middle"/>-->
      <!--            修改密码-->
      <!--          </DropdownItem>-->
      <!--          <DropdownItem name="loginOut">-->
      <!--            <Icon type="md-log-out" style="margin-top: -3px; vertical-align: middle"/>-->
      <!--            退出登录-->
      <!--          </DropdownItem>-->
      <!--        </DropdownMenu>-->
      <!--      </Dropdown>-->
    </div>
    <a href="/asynq" target="_blank" class="asynq">
      <Icon type="md-pulse"/>
      分析图表
    </a>
    <!-- 顶部菜单 -->
    <div class="topMenu" style="display: none">
      <Menu mode="horizontal" :theme="colorTheme" :active-name="topMenuActive" @on-select="menuSelect">
        <MenuItem name="1">
          <Icon type="md-desktop" />
          安责险平台
        </MenuItem>
        <MenuItem name="2" v-if="middlegroundShow">
          <Icon type="md-desktop" />
          中台
        </MenuItem>
        <MenuItem name="3">
          <Icon type="md-desktop" />
          IDI平台
        </MenuItem>
      </Menu>
    </div>
    <ys-modal v-model="modFlag" title="修改密码">
      <modPassword @on-ok="loginOut"></modPassword>
    </ys-modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import modPassword from '_c/header/ModPwd.vue'
import ysadmin from 'ys-admin'

export default {
  name: "top-header",
  computed: {
    ...mapGetters(['userInfo', 'topMenuActive']),
    colorTheme() {
      return ysadmin.colorTheme
    },
    middlegroundShow() {
      return window.location.href.indexOf('cicaq') === -1
    }
  },
  components: {modPassword},
  data() {
    return {
      modFlag: false,
    }
  },
  mounted() {},
  methods: {
    ...mapMutations(['setToken', 'setUserInfo', 'setTopMuanActive']),
		...mapActions(['getMenuList']),
    loginOut() {
      this.$Modal.confirm({
        title: '提示',
        content: '确定要退出系统么？',
        onOk: () => {
          this.setToken();
          this.setUserInfo();
          sessionStorage.clear();
          this.$router.push({name: 'login'})
        }
      });
    },
    set(name) {
      switch (name) {
        case 'modPass':
          this.modFlag = true;
          break;
        case 'loginOut':
          this.$Modal.confirm({
            title: '提示',
            content: '确定要退出系统么？',
            onOk: () => {
              this.loginOut();
            }
          });
          break;
      }
    },
    // 头部导航栏切换
    menuSelect(name) {
      let pageTabList = JSON.parse(localStorage.getItem('pageTabList')).slice(0,1)
      localStorage.setItem('pageTabList', JSON.stringify(pageTabList));
      this.$router.push({
        name: pageTabList[0].name
      })
      this.setTopMuanActive(name)
      this.getMenuList()
    }
  }
}
</script>

<style scoped lang="less">
.config {
  display: flex;
}

.topHeader {
  line-height: 45px;
  display: flex;
  flex: 1;
  flex-direction: row-reverse;

  .asynq {
    display: block;
  }
  .topMenu {
    flex: 1;
  }

  .avatar {
    .avatar-dropdown {
      padding: 0 10px;
      cursor: pointer;
      height: 45px;
    }
  }
}
</style>
<template>
  <div :style="{'bottom':bottom+'px'}" class="totalBox" @mouseenter="bottom=0" @mouseleave="bottom=height">
    <div class="tipTitle">
      合计信息
      <!--      <Tooltip placement="top" content="" :delay="300">-->
      <!--        合计信息-->
      <!--        <Icon type="ios-alert" size="14" style="vertical-align: middle;margin-top: -2px" color="#2d8cf0"/>-->
      <!--        <div slot="content">-->
      <!--          <p>第一行为当前页合计信息</p>-->
      <!--          <p>第二行为所有数据合计信息</p>-->
      <!--        </div>-->
      <!--      </Tooltip>-->
    </div>
    <div :style="{width:width }" id="totalBoxArea">
      <Table
          :show-header="false"
          :highlight-row="true"
          stripe
          border
          v-if="totalTable"
          :data="totalData"
          :columns="headerColumns"></Table>
    </div>
  </div>
</template>

<script>
import bus from '../../bus/index'

export default {
  name: "index",
  props: {
    headerColumns: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      },
    }
  },
  computed: {
    width() {
      return this.config.collapse ? 'calc(100vw  - 90px)' : 'calc(100vw  - 220px)';
    },
  },
  data() {
    return {
      config: localStorage.getItem('set') ? JSON.parse(localStorage.getItem('set')) : {},
      bottom: -500,
      height: 0,
      totalData: [],
      totalTable: false,
    }
  },
  mounted() {
    bus.$on('set', (data) => {
      this.config = data
    })
    this.setInit();
  },
  methods: {
    setInit() {
      const {allData} = this.data
      if (!allData) return;
      this.totalData = [];
      if (allData['pageTotal']) this.totalData.push(allData['pageTotal']);
      if (allData['allTotal']) this.totalData.push(allData['allTotal']);

      this.totalTable = true;
      window.setTimeout(() => {
        if (document.getElementById('totalBoxArea')) {
          let height = document.getElementById('totalBoxArea').offsetHeight;
          this.bottom = -height;
          this.height = -height;
          const l = document.getElementById('totalBoxArea').childNodes[0].childNodes[0].childNodes[4];
          const r = document.querySelector('.table').childNodes[0].childNodes[4];
          l.addEventListener('scroll', () => {
            r.scrollLeft = l.scrollLeft
          }, true);
          r.addEventListener('scroll', function () {
            l.scrollLeft = r.scrollLeft
          }, true);
        }
      }, 500);
    }
  }
}
</script>

<style>
.totalBox {
  position: absolute;
  left: 0;
  background-color: #fff;
  bottom: -140px;
  z-index: 100;
  transition: bottom .5s ease;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2)
}

.tipTitle {
  position: absolute;
  left: 50%;
  margin-left: -60px;
  top: -27px;
  padding: 6px 25px;
  border-radius: 3px 3px 0 0;
  background-color: #fff;
  box-shadow: -3px 0 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
</style>

import routerContainer from '@/views/routerContainer.vue'

/*默认设置的路由，如果接口未返回路由则启用*/
// 1.安责险平台
const azxDefaultRouters = [
  {
    path: '/main/workStation',
    name: 'workStation',
    meta: {title: '工作台', index: '1', home: true, icon: 'md-easel'},
    component: () => import('../views/home/index.vue')
  },
  {
    path: "/main/task",
    name: "task",
    meta: {title: '任务管理', index: '2', icon: 'md-menu'},
    redirect: '/main/task/list',
    component: routerContainer,
    children: [
      {
        path: '/main/task/list',
        name: 'taskList',
        meta: {title: "所有任务", index: "2-1", icon: "md-aperture", hideInMenu: false},
        component: () => import('../views/task/list/index.vue')
      },
      {
        path: '/main/task/list/detail/:id',
        name: 'taskListDetail',
        meta: {title: '任务详情', index: '2-2', icon: 'md-aperture', hideInMenu: true},
        component: () => import('../views/task/list/detail.vue')
      },
    ]
  },
  {
    path: "/main/projects",
    name: "projects",
    meta: {title: '项目库', index: '3', icon: 'md-stats'},
    redirect: '/main/projects/list',
    component: routerContainer,
    children: [
      {
        path: '/main/projects/list',
        name: 'projectsList',
        meta: {title: "项目列表", index: "3-1", icon: "md-stats", hideInMenu: false},
        component: () => import('../views/projects/list/index.vue')
      },
      {
        path: '/main/projects/list/detail/:id',
        name: 'projectsDetail',
        meta: {title: '项目详情', index: '3-2', icon: 'md-stats', hideInMenu: true},
        component: () => import('../views/projects/list/detail.vue')
      },
      {
        path: '/main/projects/InspectionData',
        name: 'InspectionData',
        meta: {title: "检查资料", index: "3-3", icon: "md-stats", hideInMenu: false},
        component: () => import('../views/projects/InspectionData/index.vue')
      },
    ]
  },
  {
    path: "/main/reportManager",
    name: "reportManager",
    meta: {title: '报告管理', index: '4', icon: 'md-menu'},
    redirect: '/main/reportManager/month',
    component: routerContainer,
    children: [
      {
        path: '/main/reportManager/brief',
        name: 'brief',
        meta: {title: "服务简报", index: "4-1", icon: "md-list", hideInMenu: false},
        component: () => import('../views/reportManager/brief/index.vue')
      },
      {
        path: '/main/reportManager/brief/detail/:id',
        name: 'briefDetail',
        meta: {title: "服务简报详情", index: "4-2", icon: "md-list", hideInMenu: true},
        component: () => import('../views/reportManager/brief/detail.vue')
      },
      {
        path: '/main/reportManager/month',
        name: 'month',
        meta: {title: "服务月报", index: "4-3", icon: "ios-paper-outline", hideInMenu: false},
        component: () => import('../views/reportManager/month/index.vue')
      },
      {
        path: '/main/reportManager/month/detail/:id',
        name: 'monthDetail',
        meta: {title: "服务月报详情", index: "4-5", icon: "ios-paper-outline", hideInMenu: true},
        component: () => import('../views/reportManager/month/detail.vue')
      },
      {
        path: "/main/reportManager/azxThirdPartyService",
        name: "azxThirdPartyService",
        meta: {title: '安责险第三方服务隐患', index: '4-1', icon: 'ios-briefcase'},
        component: () => import('../views/reportManager/azxThirdPartyService/index.vue')
      },
    
    ]
  },
  {
    path: "/main/base",
    name: "base",
    meta: {title: '系统配置', index: '4', icon: 'ios-cog'},
    redirect: '/main/base/baseData',
    component: routerContainer,
    children: [
      {
        path: '/main/base/notice',
        name: 'notice',
        meta: {title: "通知管理", index: "4-1", icon: "ios-notifications-outline"},
        redirect: '/main/base/notice/configure',
        component: routerContainer,
        children: [
          {
            path: '/main/base/notice/sendRecord',
            name: 'noticeSendRecord',
            meta: {title: "发送记录", index: "4-1-1", icon: "ios-paper-outline"},
            component: () => import('../views/base/notice/sendRecord.vue')
          },
          {
            path: '/main/base/notice/manual',
            name: 'noticeManual',
            meta: {title: "手动发送", index: "4-1-2", icon: "ios-checkbox"},
            component: () => import('../views/base/notice/manual/index.vue')
          },
          {
            path: '/main/base/notice/configure',
            name: 'noticeConfigure',
            meta: {title: "通知配置", index: "4-1-3", icon: "md-options"},
            component: () => import('../views/base/notice/configure/index.vue')
          },
          {
            path: '/main/base/notice/manage',
            name: 'noticeManage',
            meta: {title: "通知管理", index: "4-1-4", icon: "md-options"},
            component: () => import('../views/base/notice/manage/index.vue')
          },
        ]
      },
      {
        path: "/main/base/smartDevice",
        name: "smartDevice",
        meta: {title: '智能设备管理', index: '4-2', icon: 'md-tablet-portrait'},
        component: () => import('../views/base/smartDevice/index'),
      },
    ]
  },
  //路由不要写在这个后面
  {path: '/main/*', name: 'main-404', meta: {icon: 'ios-appstore', title: '机构成员详情', index: '99', hideInMenu: true}, component: () => import('../components/404')},
]

// 2.中台
const middleOfficeDefaultRouters = [
  {
    path: '/main/workStation',
    name: 'workStation',
    meta: {title: '工作台', index: '3', home: true, icon: 'md-easel'},
    component: () => import('../views/home/index.vue')
  },
  //路由不要写在这个后面
  {path: '/main/*', name: 'main-404', meta: {icon: 'ios-appstore', title: '机构成员详情', index: '99', hideInMenu: true}, component: () => import('../components/404')},
]

// 3.IDI平台
const IDIDefaultRouters = [
  {
    path: '/main/workStation',
    name: 'workStation',
    meta: {title: '工作台', index: '3', home: true, icon: 'md-easel'},
    component: () => import('../views/home/index.vue')
  },
  {
    path: "/main/task",
    name: "task",
    meta: {title: '任务管理', index: '1', icon: 'md-menu'},
    redirect: '/main/task/list',
    component: routerContainer,
    children: [
      {
        path: '/main/task/list',
        name: 'taskList',
        meta: {title: "所有任务", index: "1-1", icon: "md-aperture", hideInMenu: false},
        component: () => import('../views/task/list/index.vue')
      },
      {
        path: '/main/task/list/detail/:id',
        name: 'taskListDetail',
        meta: {title: '任务详情', index: '1-2', icon: 'md-aperture', hideInMenu: true},
        component: () => import('../views/task/list/detail.vue')
      },
    ]
  },
  //路由不要写在这个后面
  {path: '/main/*', name: 'main-404', meta: {icon: 'ios-appstore', title: '机构成员详情', index: '99', hideInMenu: true}, component: () => import('../components/404')},
]

export {
  azxDefaultRouters,
  middleOfficeDefaultRouters,
  IDIDefaultRouters,
}
<template>
  <div :class="classCls" :style="{'width':width?width+'px':'auto','height':height?height+'px':'auto'}">
    <span v-if="treeData.length <= 0">{{ emptyText }}</span>
    <draggable filter=".ys-tree-item-content-arrow" v-model="treeData" :group="draggableSkipLevel?'tree':0" @start="onStart" animation="300" @end="onEnd" dragClass="dragClass" ghostClass="ghostClass" chosenClass="chosenClass" :disabled="!draggable" v-else>
        <tree-item
            v-for="(item) in treeData"
            :indent="indent"
            :nodeKey="nodeKey"
            :draggableSkipLevel="draggableSkipLevel"
            :defaultExpandAll="defaultExpandAll"
            :draggable="draggable"
            :checkedType="checkedType"
            :render="render"
            :showCheckbox="showCheckbox"
            :highlightCurrent="highlightCurrent"
            @on-start="onItemStart"
            @on-end="onItemEnd"
            @on-select-change="selectChange"
            @on-toggle-expand="onToggleExpand"
            @on-checkbox-change="checkBoxChange"
            :itemData="item"
            :key="item[nodeKey]"></tree-item>
    </draggable>
  </div>
</template>
<script>
import treeItem from "./tree-item";

const prefixCls = 'ys-tree';
import draggable from "vuedraggable";

export default {
  name: 'YsTree',
  components: {draggable, treeItem},
  props: {
    data: {type: Array, default: () => []},
    defaultCheckedKeys: {type: Array, default: () => []},//默认勾选的key的数据
    defaultSelectKey: {type: [String, Number], default: ''},//默认选中的key，单个
    defaultExpandedKeys: {type: Array, default: () => []},//默认展开的节点的 key 的数组
    accordion: {type: Boolean, default: false},//是否每次只打开一个同级树节点展开
    checkedType: {type: String, default: 'strictly'},//勾选节点的方式，'strictly':严格遵循父子互相关联，子全选，父才选中，否则半选或者不选中,'allWeak':子选中其中一个父比选中,'weak',父子不互相关联]
    draggableSkipLevel: {type: Boolean, default: false},//拖拽的方式，'false':不可跨级拖拽，'true':可跨级拖拽]
    showCheckbox: {type: Boolean, default: false},//节点是否可被选择
    defaultExpandAll: {type: Boolean, default: false},//是否默认展开所有节点
    highlightCurrent: {type: Boolean, default: true},//是否高亮当前选中节点。
    draggable: {type: Boolean, default: false},//是否开启拖拽节点功能
    expandOnClickNode: {type: Boolean, default: true},//是否在点击节点的时候展开或者收缩节点
    checkOnClickNode: {type: Boolean, default: false},//是否在点击节点的时候选中节点
    indent: {type: Number, default: 20},//相邻级节点间的水平缩进，单位为像素
    width: {type: Number, default: null},
    height: {type: Number, default: null},
    render: {type: Function},
    emptyText: {type: String, default: '暂无数据！'},
    nodeKey: {type: String, default: 'id'},//每个树节点用来作为唯一标识的属性，整棵树应该是唯一的
  },
  computed: {
    classCls() {
      return prefixCls;
    },
  },
  data() {
    return {
      treeData: this.data,
      selectKey: '', // 选择(鼠标点击选中的当前节点)
      expandKeys: [], // 展开(被展开的节点)
      checkedKeys: [], // 选中(被勾选中的节点)
    }
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.initExpandKeys();
        this.getTreeData();
      }
    }
  },
  mounted() {
    this.expandKeys = this.defaultExpandedKeys;
    this.selectKey = this.defaultSelectKey;
    this.checkedKeys = this.defaultCheckedKeys;
    this.initExpandKeys();
    this.getTreeData();
  },
  methods: {
    initExpandKeys() {
      this.expandKeys = [];
      const deepDoTree = (target) => {
        return target.forEach(({...item}) => {
          if (item.expand) this.expandKeys.push(item[this.nodeKey]);
          if (item?.children) {
            item.children = deepDoTree(item.children);
          }
        })
      }
      this.treeData = deepDoTree(this.data);
    },
    getTreeData(data) {
      const deepDoTree = (target, _curIndentLevel = 0) => {
        return target.map(({...item}) => {
          if (this.defaultExpandAll) item.expand = true;
          item.selected = item[this.nodeKey] === this.selectKey;
          item.checked = this.checkedKeys.some(checkedItem => checkedItem[this.nodeKey] == item[this.nodeKey])
          item.expand = this.expandKeys.indexOf(item[this.nodeKey]) >= 0;
          item._curIndentLevel = _curIndentLevel;
          item.children = item.children || [];
          if (item?.children) {
            item.children = deepDoTree(item.children, _curIndentLevel + 1);
          }
          return item
        })
      }
      this.treeData = deepDoTree(data || this.data);
    },
    onItemEnd(oldData, newData) {
      this.$emit('on-draggable-end', oldData, newData);
    },
    onItemStart(data) {
      this.$emit('on-draggable-start', data);
    },
    onEnd(data) {
      const {oldIndex, newIndex} = data;
      this.$emit('on-draggable-end', this.treeData[oldIndex], this.treeData[newIndex]);
    },
    onStart(data) {
      const {oldIndex} = data;
      this.$emit('on-draggable-start', this.treeData[oldIndex]);
    },
    selectChange(data) {
      this.selectKey = data[this.nodeKey] === this.selectKey ? '' : data[this.nodeKey];
      this.getTreeData(this.treeData);
      this.$emit('on-select-change', this.selectKey ? data : {})
      if (this.expandOnClickNode) this.onToggleExpand(data)
      if (this.checkOnClickNode) this.automaticSelection(!data.checked, data)
    },
    onToggleExpand(item) {
      let status = !item.expand
      if (this.accordion) this.expandKeys = [];
      let currentIndex = this.expandKeys.indexOf(item[this.nodeKey]);
      currentIndex >= 0 ? this.expandKeys.splice(currentIndex, 1) : this.expandKeys.push(item[this.nodeKey]);
      this.getTreeData(this.treeData);
      this.$emit('on-toggle-expand', status, item)
    },
    checkBoxChange(state, item) {
      this.selectKey = item[this.nodeKey] === this.selectKey ? '' : item[this.nodeKey];
      this.automaticSelection(state, item)
      this.$emit('on-checkbox-change', state, item)
    },
    // 有关选择节点后自动决定与其关联的父与子的选定
    automaticSelection(state, data) {
      let fatherData = []
      let childrenData = []
      // 找所有祖先
      const findAncestors = (data) => {
        const findFather = (list) => {
          return list.map(item => {
            if(item.children.some(childrenItem => childrenItem[this.nodeKey] == data[this.nodeKey])) {
              fatherData.push(item)
            }
            if (item?.children) return findFather(item.children)
          })
        }
        findFather(this.treeData)
        // 去最后一位元素
        if(fatherData.length > 0 && [...fatherData].pop()._curIndentLevel !== 0) {
          return findAncestors([...fatherData].pop())
        }
      }
      findAncestors(data)
      // 找所有后代（包含自己本身）
      const findOffspring = (data) => {
        let newData = []
        const findChildren = (list) => {
          return list.forEach(item => {
            if(item[this.nodeKey] == data[this.nodeKey]) {
              childrenData.push(item)
              newData = item
            }
            if (item?.children) return findChildren(item.children)
          })
        }
        findChildren(this.treeData)
        if (newData.children.length > 0) {
          newData.children.map((item) => {
            findOffspring(item)
          })
        }
      }
      findOffspring(data)
      // 再重新选中之前先把原先存在于this.checkedKeys中的与当前点击节点存在亲情关系的数据剔除掉
      this.checkedKeys = this.checkedKeys.filter((item) => {
        return !childrenData.concat(this.checkedType == 'allWeak' ? [] : fatherData).some((childrenItem) => item[this.nodeKey] === childrenItem[this.nodeKey])
      })
      switch(this.checkedType) {
        case 'strictly':
          if (state) {
            // 若当前是要被选中，则执行先往下将所有的子都选中放入到checkedKeys中，往上(一定要一层一层从内到外)寻找父内的所有子是否都存在于this.checkedKeys中，存在则是要被选中
            // 父控制子的全选
            this.checkedKeys = this.checkedKeys.concat(childrenData)
            // 子(由内到外一层一层)控制父的选中
            fatherData.forEach(item => {
              if (item?.children) {
                 let flag = item.children.every((childrenItem) => {
                  return this.checkedKeys.some((checkedItem) => {
                    return checkedItem[this.nodeKey] == childrenItem[this.nodeKey]
                  })
                });
                if(flag) {
                  this.checkedKeys.push(item) 
                }
              }
            })
          }
          break;
        case 'allWeak':
          if (state) {
            this.checkedKeys = this.checkedKeys.concat(childrenData)
            fatherData.forEach(item => {
              if (item?.children) {
                 let flag = item.children.some((childrenItem) => {
                  return this.checkedKeys.some((checkedItem) => {
                    return checkedItem[this.nodeKey] == childrenItem[this.nodeKey]
                  })
                });
                if(flag) {
                  this.checkedKeys.push(item) 
                }
              }
            })
          }
          break;
      }
      this.getTreeData()
    }
  }
}
</script>

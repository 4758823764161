import { render, staticRenderFns } from "./header.vue?vue&type=template&id=2390c214&scoped=true&"
import script from "./header.vue?vue&type=script&lang=js&"
export * from "./header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../ys-manager/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2390c214",
  null
  
)

export default component.exports
<template>
  <div>
    <Spin fix v-if="loading">
      <Icon type="ios-loading" size="35" class="spin-icon-load"></Icon>
      <div>加载中，请稍后......</div>
    </Spin>
    
    <router-view v-else></router-view>
  </div>
</template>
<script>
import {getDict} from "@/api/common";
import {mapActions, mapMutations, mapGetters} from 'vuex'

export default {
  name: 'app',
  data() {
    return {
      loading: true
    }
  },
  created() {
    getDict().then(res => {
      if (res.code === 200) {
        let baseData = res.data;
        baseData['隐患回复状态'] = [
          {name: '通过', value: 4, color: '', code: ''},
          {name: '通过', value: 3, color: '', code: ''},
          {name: '已整改', value: 2, color: '', code: ''},
          {name: '驳回', value: 1, color: '', code: ''},
          {name: '回复', value: 0, color: '', code: ''},
        ]
        this.setBaseData(res.data);
      }
    })
  },
  computed: {
    ...mapGetters(['token'])
  },
  mounted() {
    this.getHeight();
    if (this.token) this.getMenuList();
    window.addEventListener('resize', this.onWindowResize, false);
    window.setTimeout(() => {
      this.loading = false
    }, 500);
  },
  methods: {
    ...mapActions(['getMenuList']),
    ...mapMutations(['setBaseData', 'setHeight']),
    getHeight() {
      this.setHeight(document.documentElement.clientHeight)
    },
    onWindowResize() {
      let timer = null;
      window.clearTimeout(timer);
      timer = window.setTimeout(() => {
        this.getHeight();
      }, 100)
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize, false);
  }
}
</script>
<style lang="less" scoped>

</style>

import {get} from '../../request/index'
/**
 * 查询整改单详情
 * @param {Object} params
 * @returns {Object}
 */
export const getSubsAndLevelOptsById = function (params) {
  return get('orgExamineConfig/GetSubsAndLevelOptsById', params)
};


/**
 * 查询整改单详情
 * @param {Object} params
 * @returns {Object}
 */
export const examineList = function (params) {
  return get('/orgExamineConfig/List', params)
};


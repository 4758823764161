<template>
  <div :class="classes">
    <Form ref="form" :model="form" :label-width="110">
      <Divider>风格主题设置</Divider>
      <FormItem label="主题风格">
        <RadioGroup size="small" v-model="form.theme" type="button" @on-change="themeChange">
          <Radio label="dark">
            <Icon type="ios-moon"/>
            深色
          </Radio>
          <Radio label="light">
            <Icon type="md-sunny"/>
            亮色
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="主题颜色">
        <RadioGroup size="small" v-model="form.color" type="button" @on-change="saveSet('reload')">
          <Radio :label="item" v-for="item in colorList" :key="item" :style="{padding:'0 5px','background-color': '#'+ item,'border-color': '#'+ item,'margin-left':'5px'}">
            <Icon type="md-checkmark" color="#ffffff" v-if="form.color===item"/>
            <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="顶部栏位置">
        <RadioGroup size="small" v-model="form.headerType" type="button" @on-change="headerTypeChange">
          <Radio label="top">顶部</Radio>
          <Radio label="inner">内部</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="是否滚屏">
        <i-switch v-model="form.scroll" size="large" @on-change="saveSet">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="整体宽度">
        <Slider v-model="form.width" @on-input="saveSet" :tip-format="format" :min="60" :max="100"></Slider>
      </FormItem>
      <Divider>菜单栏设置</Divider>
      <FormItem label="菜单风格">
        <RadioGroup size="small" v-model="form.menuTheme" type="button" @on-change="saveSet">
          <Radio label="dark" :disabled="form.theme==='dark'">
            <Icon type="ios-moon"/>
            深色
          </Radio>
          <Radio label="light" :disabled="form.theme==='dark'">
            <Icon type="md-sunny"/>
            亮色
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="菜单栏">
        <i-switch v-model="form.collapse" size="large" @on-change="saveSet">
          <span slot="open">折叠</span>
          <span slot="close">展开</span>
        </i-switch>
      </FormItem>
      <FormItem label="折叠按钮位置">
        <RadioGroup size="small" v-model="form.collapsePosition" :disabled="form.headerType === 'top'" type="button" @on-change="saveSet">
          <Radio label="left" :disabled="form.headerType === 'top'"> 左侧</Radio>
          <Radio label="header" :disabled="form.headerType === 'top'"> 顶部</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="手风琴模式">
        <i-switch v-model="form.accordion" size="large" @on-change="saveSet">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </FormItem>
      <FormItem label="菜单展开宽度">
        <Slider v-model="form.menuWidth" :step="10" @on-input="saveSet" :tip-format="menuWidthFormat" :min="200" :max="400"></Slider>
      </FormItem>
      <Divider>列表表格/其他设置</Divider>
      <FormItem label="表格列表风格">
        <RadioGroup size="small" v-model="form.size" type="button" @on-change="saveSet">
          <Radio v-for="item in themeList" :label="item.value" :value="item.value" :key="item.value">{{ item.name }}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="操作列栏位置">
        <RadioGroup size="small" v-model="form.fixed" type="button" @on-change="saveSet('reload')">
          <Radio label="left">居左</Radio>
          <Radio label="right">居右</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="查看时收侧边栏">
        <i-switch v-model="form.drawerCollapsed" size="large" @on-change="saveSet">
          <span slot="open">收起</span>
          <span slot="close">保持</span>
        </i-switch>
      </FormItem>
      <FormItem label="点整行则选中">
        <i-switch v-model="form.lineSelect" :disabled="form.lineSelectOverlap" size="large" @on-change="saveSet">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </FormItem>
      <FormItem label="整行选择累加">
        <i-switch v-model="form.lineSelectOverlap" size="large" @on-change="saveSet">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </FormItem>
      <FormItem label="隔行换色">
        <i-switch v-model="form.stripe" size="large" @on-change="saveSet">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </FormItem>
      <FormItem label="表格边框">
        <i-switch v-model="form.border" size="large" @on-change="saveSet">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </FormItem>
      <FormItem label="每页条数" prop="pageSize">
        <Select v-model="form.pageSize" size="small" style="width: 80px;" @on-select="pageSizeChange">
          <Option v-for="item in pageSizeList" :value="item" :key="item">{{ item }}</Option>
        </Select>
      </FormItem>
    </Form>
    <Alert type="warning">您也可以<a href="javascript:;" @click="reset">重置</a>以上设置。</Alert>
  </div>
</template>

<script>
import bus from '../../../bus/index'

const prefixCls = 'ys-set';

export default {
  name: "ys-set",
  data() {
    return {
      colorList: ['2d8cf0', '7800d0','13c2c2','52c41a','fa541c'],
      themeList: [{name: '紧凑', value: 'small'}, {name: '正常', value: 'default'}, {name: '宽松', value: 'large'},],
      pageSizeList: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      form: {
        pageSize: 30,
        menuWidth: 250,
        width: 100,
        color: '2d8cf0',
        collapsePosition: 'header',
        size: 'default',
        fixed: 'right',
        headerType: 'inner',
        theme: 'light',
        menuTheme: 'dark',
        collapse: false,
        accordion: true,
        drawerCollapsed: false,
        scroll: false,
        lineSelect: true,
        lineSelectOverlap: false,
        stripe: true,
        border: true,
      }
    }
  },
  computed: {
    classes() {
      return prefixCls
    },
  },
  created() {
    let formSet = localStorage.getItem('set')
    if (formSet) {
      Object.keys(this.form).forEach(item => {
        this.form[item] = JSON.parse(formSet)[item]
      })
    } else {
      this.saveSet()
    }
  },
  methods: {
    format(val) {
      return '宽度: ' + val + '%';
    },
    menuWidthFormat(val) {
      return '宽度: ' + val + 'px';
    },
    pageSizeChange(data) {
      this.form.pageSize = data.value;
      this.saveSet();
    },
    headerTypeChange() {
      if (this.form.headerType === 'top') this.form.collapsePosition = 'left';
      this.saveSet();
      window.location.reload();
    },
    themeChange() {
      if (this.form.theme === 'dark') this.form.menuTheme = 'dark';
      this.saveSet();
      window.location.reload();
    },
    reset(){
      localStorage.clear();
      window.location.reload()
    },
    saveSet(type) {
      if (this.form.lineSelectOverlap) this.form.lineSelect = true;
      bus.$emit('set', this.form);
      localStorage.setItem('set', JSON.stringify(this.form));
      if (type === 'reload') window.location.reload();
    }
  }
}
</script>

<style scoped lang="less">
</style>
<template>
  <div :class="logoCls">
    <img :class="`${classCls}-img`" src="../../../assets/images/logo.png" alt="logo">
    <h1 :class="`${classCls}-title`" v-show="!collapse">运维管理系统</h1>
  </div>
</template>

<script>

const prefixCls = 'ys-container-demo-logo';
export default {
  name: "ys-container",
  props: {
    theme: {type: String, default: 'dark'},
    collapse: {type: Boolean, default: false},
  },
  computed: {
    logoCls(){
      return [`${prefixCls}`,`${prefixCls}-`+this.theme]
    },
    classCls(){
      return [prefixCls]
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="main">
    <div class="logo" v-if="platformConfig.Logo">
      <img :src="platformConfig.Logo.url" alt="" class="logo">
    </div>
    <div class="title">{{ config.title || '安责险综合管理云平台' }}</div>
    <ul class="nav">
      <li :key="index" v-for="(item,index) in nav">
        <template v-if="$enterNav.indexOf(item.name)>=0">
          <a :href="'//www'+host" v-if="host==='.cicaq.cn'&&item.name==='ins'"> <img :src="item.src"> </a>
          <a :href="'//'+item.name+host" v-else> <img :src="item.src"> </a>
        </template>
      </li>
    </ul>
    <div class="copyright" v-html="platformConfig.copyright || ''"></div>
  </div>
</template>

<script>
import {get, all} from "../../request";

export default {
  name: "home",
  data() {
    return {
      host: '',
      config: {},
      platformConfig: {},
      nav: [
        {name: 'gov', src: require('./images/gov.png')},
        {name: 'ins', src: require('./images/ins.png')},
        {name: 'ent', src: require('./images/ent.png')},
        {name: 'ser', src: require('./images/ser.png')},
        {name: 'ter', src: require('./images/ter.png')},
      ]
    }
  },
  created() {
    const domain = window.location.host;
    this.getSiteConfig();
    if (domain.indexOf('.cicaq.cn') >= 0) {
      this.host = '.cicaq.cn';
    } else {
      let hostSplit = window.location.host.split('.');
      let hostPer = hostSplit[0];
      if (hostPer.split('-').length > 1) {
        this.host = '-' + hostPer.split('-')[1] + '.us-smart.cn';
      } else {
        this.host = '//';
      }
    }
  },
  methods: {
    getSiteConfig() {
      const domain = window.location.host;
      all([
        get('/systemConfig/GetSystemInfo'),
        get('/platformConfig/GetByDomain', {domain: domain})
      ]).then((res) => {
        let perHost = '';
        if (domain.indexOf('.cicaq.cn') >= 0) {
          perHost = domain.split('.cicaq.cn')[0];
          perHost = perHost === 'www' ? 'ins' : perHost;
        } else {
          perHost = domain.split('-')[0];
        }
        document.title = res[0].data[perHost + 'Title'];
        this.config = res[0].data;
        this.platformConfig = res[1].data;
        this.config.title = res[0].data[perHost + 'Title'];
      })
    },
  }
}
</script>

<style scoped lang="less">
.main {
  background-image: url("./images/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  position: absolute;

  .copyright {
    position: fixed;
    left: 0;
    text-align: center;
    width: 100%;
    bottom: 30px;
    color: #FFFFFF;
  }

  .logo {
    max-width: 300px;
    max-height: 200px;
    text-align: center;
    margin: 0 auto;
    padding: 30px 0;
  }

  .title {
    text-align: center;
    color: #30d9da;
    padding-top: 10px;
    font-weight: bold;
    font-size: 45px;
  }

  .nav {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    li {
      margin: 0 2vw;
      list-style: none;

      img {
        width: 10vw;
        min-width: 180px;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
</style>
<template>
  <div :class="classCls">
    <div :class="contentCls" :style="{paddingLeft:indent*itemData._curIndentLevel+'px'}">
      <!-- 节点箭头 -->
      <div :class="arrowCls" @click="toggleArrow(itemData)">
        <Icon type="ios-arrow-forward" v-if="Array.isArray(itemData.children)&&itemData.children.length>0"/>
      </div>
      <!-- 复选框 -->
      <Checkbox v-model="itemData.checked" :class="checkCls" v-if="showCheckbox" @on-change="checkBoxChange($event,itemData)"></Checkbox>
      <!-- 数据 -->
      <div :class="titleCls" @click="toggleSelect(itemData)">
        <Render :data="itemData" v-if="render" :render="render"></Render>
        <span v-else>{{ itemData.title }}</span>
      </div>
    </div>
    <!-- children中的数据(相当于一个递归，只有当没有children的时候才不会渲染) -->
    <div :class="childrenCls" v-if="itemData&&Array.isArray(itemData.children)&&itemData.children.length>0">
      <draggable filter=".filter" @start="onStart" animation="300" @end="onEnd" v-model="itemData.children" :group="draggableSkipLevel?'tree':itemData[nodeKey]" chosenClass="chosenClass" :disabled="!draggable">
          <tree-item
              v-for="(item) in itemData.children"
              :render="render"
              :showCheckbox="showCheckbox"
              :draggable="draggable"
              :indent="indent"
              :nodeKey="nodeKey"
              :highlightCurrent="highlightCurrent"
              @on-toggle-expand="toggleArrow"
              @on-select-change="toggleSelect"
              @on-checkbox-change="checkBoxChange"
              :itemData="item"
              :key="item[nodeKey]"></tree-item>
      </draggable>
    </div>
  </div>
</template>
<script>
const prefixCls = 'ys-tree-item';
import draggable from "vuedraggable";
import Render from "./render";
import treeItem from './tree-item.vue'

export default {
  name: 'tree-item',
  components: {draggable, treeItem, Render},
  props: {
    draggableSkipLevel: {type: Boolean, default: false},//拖拽的方式，'false':不可跨级拖拽，'true':可跨级拖拽]
    draggable: {type: Boolean, default: false},//是否开启拖拽节点功能
    nodeKey: {type: String, default: 'id！'},//每个树节点用来作为唯一标识的属性，整棵树应该是唯一的
    indent: {type: Number, default: 20},//相邻级节点间的水平缩进，单位为像素
    render: {type: Function},
    checkedType: {type: String, default: 'strictly'},//勾选节点的方式，'strictly':严格遵循父子互相关联，子全选，父才选中，否则半选或者不选中,'allWeak':子选中其中一个父比选中,'weak',父子不互相关联]
    showCheckbox: {type: Boolean, default: false},//节点是否可被选择
    highlightCurrent: {type: Boolean, default: false},//是否高亮当前选中节点。
    itemData: {
      type: Object, default: () => {
        return {}
      }
    },
  },
  computed: {
    classCls() {
      return prefixCls;
    },
    contentCls() {
      return [
        `${prefixCls}-content`,
        {
          [`${prefixCls}-content-selected`]: this.itemData.selected && this.highlightCurrent,
        }
      ];
    },
    arrowCls() {
      return [
        `${prefixCls}-content-arrow`,
          'filter',
        {
          [`${prefixCls}-content-arrow-open`]: this.itemData.expand,
          [`${prefixCls}-content-arrow-disabled`]: this.itemData.disabled,
        }
      ];
    },
    checkCls() {
      return [`${prefixCls}-content-check`];
    },
    titleCls() {
      return [`${prefixCls}-content-title`];
    },
    childrenCls() {
      return [
        `${prefixCls}-children`,
        {
          [`${prefixCls}-children-open`]: this.itemData.expand,
        }
      ];
    },
  },
  data() {
    return {
      checkItem: []
    }
  },
  mounted() {},
  methods: {
    onStart(data) {
      const {oldIndex} = data;
      this.$emit('on-start', this.itemData.children[oldIndex]);
    },
    onEnd(data) {
      const {oldIndex, newIndex} = data;
      this.$emit('on-end', this.itemData.children[oldIndex], this.itemData.children[newIndex]);
    },
    toggleSelect(data) {
      this.$emit('on-select-change', data || this.itemData)
    },
    toggleArrow(item) {
      this.$emit('on-toggle-expand', item)
    },
    checkBoxChange(state, item) {
      this.$emit('on-checkbox-change', state, item)
    },
  }
}
</script>
<style type="text/css" lang="less">

</style>


<template>
  <div :class="classes">
    <Dropdown :placement="placement" transfer-class-name="regionDown" :transfer="transfer" style="width: 100%">
      <Input
          ref="regionInput" class="regionInput"
          :suffix="showFlag?'ios-arrow-up':'ios-arrow-down'"
          v-model="region"
          :size="size"
          :disabled="disabled"
          @on-clear="clear"
          @on-focus="focus"
          @click.native="showFlag=true"
          :clearable="clearable"
          style="cursor: pointer"
          readonly
          :placeholder="placeholder"/>
      <DropdownMenu slot="list" v-show="showFlag">
        <div :class="selectBoxCls" v-if="baseDataFlag">
          <Tabs v-model="tabs" name="region">
            <TabPane
                :disabled="result.length<index||(initLock&&index<lockCodeLen)"
                :label="result[index]?result[index].name:level[index]"
                :name="String(index)" v-for="(item,index) in regionDeep"
                tab="region"
                :key="item">
              <ul :class="itemCls">
                <li
                    v-for="(item) in tabRegionData[index]||[]"
                    :class="{'active':result[index]&&result[index].code===item.code,[liItemCls]:true,'disabled':item.disabled}"
                    @click="itemClick(item,index)"
                    :key="item.code">{{ item.name }}
                </li>
              </ul>
            </TabPane>
          </Tabs>
        </div>
        <div v-else class="noDataCls">
          <Icon type="ios-loading" class="spin-icon-load" size="25"></Icon>
          <div>数据初始化中，请稍后......</div>
        </div>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script lang="ts">
import {getMaxFloor, getRegionByCode, clone} from '../../lib/index'

const prefixCls = 'ys-region';

export default {
  name: 'Region',
  props: {
    changeOnSelect: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    clearable: {type: Boolean, default: true},
    size: {type: String, default: 'default'},
    transfer: {type: Boolean, default: true},
    allowCode: {type: Array, default: () => []},//允许可选区域的code数组
    initCode: {type: String, default: null},//初始code
    initLock: {type: Boolean, default: false},//是否锁定初始code不可改变
    placeholder: {type: String, default: '请选择行政地区'},
    placement: {type: String, default: 'bottom-start'},
    value: {type: [Number, String, Array], default: null},
  },
  computed: {
    classes() {
      return prefixCls
    },
    selectBoxCls() {
      return [`${prefixCls}-selectBox`]
    },
    itemCls() {
      return [`${this.selectBoxCls}-item`]
    },
    liItemCls() {
      return [`${this.itemCls}-liItem`]
    },
    regionAllData() {
      let allowMap = new Map();
      const deepSetDisabledItem = (targetAry = clone(window['regionData']), parentDisabledFlag = true, index = '') => {
        return targetAry.map(({...item}, i) => {
          let deepIndex = index === '' ? String(i) : (index + '-' + i);
          item.disabled = parentDisabledFlag === false ? false : this.allowCode.indexOf(item.code) <= -1;
          if (parentDisabledFlag && this.allowCode.indexOf(item.code) > -1) {
            if (!allowMap.get(deepIndex)) {
              allowMap.set(deepIndex, false)
            }
          }
          if (item.disabled && item.children && item.children.length > 0) {
            item.children = deepSetDisabledItem(item.children, true, deepIndex)
          }
          return item;
        })
      }
      let data = this.allowCode && this.allowCode.length > 0 ? deepSetDisabledItem() : window['regionData'];
      allowMap.forEach((value, key) => {
        let split = key.split('-');
        let len = split.length - 1;
        switch (len) {
          case 1:
            data[parseInt(split[0])].disabled = false;
            break;
          case 2:
            data[parseInt(split[0])].disabled = false;
            data[parseInt(split[0])].children[parseInt(split[1])].disabled = false;
            break;
        }
      })
      return data;
    },
  },
  data() {
    return {
      level: ['省/直辖市', '市', '区/县', '街道/乡/镇'],
      region: '',
      tabs: '0',
      lockCodeLen: -1,
      regionDeep: 1,
      result: [],
      tabRegionData: {},
      baseDataFlag: false,
      checkFlag: false,
      showFlag: false,
    }
  },
  watch: {
    value: function (data) {
      if (!data) {/*设置初始化值为空或者重置时需要清空下*/
        if (this.initCode) {
          this.init(this.initCode);
        } else {
          this.clear()
        }
        return;
      }
      this.getDeepRegion(this.regionAllData, data)
      this.init(this.value);
    }
  },
  mounted() {
    let timer = window.setInterval(() => {
      if (this.regionAllData) {
        this.baseDataFlag = true;
        window.clearInterval(timer);
        this.tabRegionData[0] = this.regionAllData;
        this.regionDeep = getMaxFloor(this.regionAllData);
        this.getDeepRegion(this.regionAllData, this.value);
        this.init(this.value || this.initCode);
      }
    }, 500)
  },
  methods: {
    init(code) {
      if (!code) return;
      if (this.initCode) {
        this.lockCodeLen = getRegionByCode(this.initCode, '-', 2).split('-').length;
      }
      const lockCodeList = getRegionByCode(code, '-', 2).split('-');
      lockCodeList.forEach((item, index) => {
        if (index === 0) {
          this.result = this.regionAllData.filter(item => item.code === lockCodeList[0]);
          this.tabRegionData[1] = clone(this.result[index].children)
        } else {
          this.result = this.result.concat(this.result[index - 1].children.filter(item => item.code === lockCodeList[index]))
          this.tabRegionData[index + 1] = clone(this.result[index].children)
        }
      });
      this.tabs = String(lockCodeList.length === this.regionDeep ? lockCodeList.length - 1 : lockCodeList.length);
      this.getRegion()
    },
    returnFun(regionMap) {
      this.$emit('input', regionMap[regionMap.length - 1].code)
      this.$emit('on-change', regionMap)
      this.$nextTick(() => {
        this.region = regionMap.map(item => item.name).join(' / ');
      })
    },
    getRegion() {
      let regionMap = [];
      this.region = ''
      if ((this.changeOnSelect && this.result.length > 0) || this.result.length === this.regionDeep) {
        regionMap = this.result.map(({...item}) => {
          let itemTemp = clone(item)
          if (itemTemp.children) delete itemTemp.children;
          return itemTemp
        })
        if (this.allowCode && this.allowCode.length > 0) {
          for (const ele of regionMap) {
            if (this.allowCode.indexOf(ele.code) >= 0) this.returnFun(regionMap);
          }
        } else {
          this.returnFun(regionMap);
        }
      }
    },
    getDeepRegion(targetData, targetVal) {
      if (!targetVal || (this.result.length > 0 && targetVal === this.result[this.result.length - 1].code)) return false;
      let checkFlag = false;
      this.result = [];
      const deepFor = (data) => {
        for (let i = 0; i < data.length; i++) {
          this.result.push(data[i])
          if (data[i].code === targetVal) {
            checkFlag = true
            return false;
          } else {
            if (data[i].children && data[i].children.length > 0) {
              deepFor(data[i].children);
            }
            if (!checkFlag) {
              this.result.splice(this.result.length - 1, 1)
            } else {
              return false;
            }
          }
        }
      }
      deepFor(targetData);
      this.$nextTick(() => {
        for (let i = 1; i < this.result.length; i++) {
          this.tabRegionData[i] = this.result[i - 1].children || []
        }
      })
    },
    itemClick(item, index) {
      if (item.disabled) return;
      if (index + 1 < this.result.length) {
        this.result.splice(index + 1, this.result.length - index - 1);
      }
      if (this.result[index]) {
        this.$set(this.result, index, item)
      } else {
        this.result.push(item)
      }
      if (index + 1 !== this.regionDeep) {
        this.tabRegionData[index + 1] = item.children;
        this.tabs = String(index + 1);
      } else {
        this.showFlag = false;
      }
      this.getRegion();
    },
    openShow(e) {
      this.showFlag = true;
      e.stopPropagation();
    },
    focus() {
      this.$emit('on-focus')
    },
    clear() {
      this.result = [];
      this.tabs = '0'
      this.region = ''
      if (this.initLock) {
        this.init(this.initCode);
      }
      this.$emit('input', '')
      this.$emit('on-change', [])
    },
  }
}
</script>
<style lang="less" scoped>
.noDataCls {
  padding: 20px 30px;
  text-align: center;
}
</style>


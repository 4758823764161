<template>
  <div>
    <Menu ref="menu" :theme="theme" :accordion="accordion" width="auto" :open-names="openNames" :active-name="activeName" v-show="!collapse">
      <ys-menu :menu-list="currentMenuList"></ys-menu>
    </Menu>
    <ys-drop-down :topMenu="true" :theme="theme" :openNames="openNames" :menuList="currentMenuList" v-show="collapse"></ys-drop-down>
  </div>
</template>

<script>
import ysMenu from "./ysMenu.vue";
import ysDropDown from "./ys-drop-down.vue";
import {getRecorderAryByDeep} from "../../../lib/index";

export default {
  name: "allMenu",
  components: {ysMenu, ysDropDown},
  props: {
    menuList: {type: Array, default: () => []},
    menuMountByRouterName: {type: String, default: 'main'},
    theme: {type: String, default: 'dark'},
    collapse: {type: Boolean, default: false},
    accordion: {type: Boolean, default: false}
  },
  data() {
    return {
      currentRoute: null,
    }
  },
  watch: {
    '$route': function (data) {
      this.currentRoute = data;
      this.$nextTick(() => {
        this.$refs["menu"].updateOpened();
        //同理这里可以更新菜单选中项
      });
    }
  },
  computed: {
    currentMenuList() {
      return this.menuList.length > 0 ? this.menuList : this.$router.options.routes.filter(item => item.name === this.menuMountByRouterName)[0].children;
    },
    activeName() {
      return this.currentRoute.name
    },
    openNames() {
      return getRecorderAryByDeep({
        targetData: this.menuList,
        targetValue: this.activeName,
        label: {valueKey: 'name', childKey: 'children'}
      });
    },
  },
  created() {
    this.currentRoute = this['$router'].currentRoute;
  },
  methods: {}
}
</script>

<style scoped>

</style>
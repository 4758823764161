export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '运维管理系统',

  /**
   * @description 默认首页的路由name值
   */
  default: 'home',

  /**
   * @description 登录页的路由name值
   */
  routerParent: 'main',

  /**
   * @description 登录页的路由name值
   */
  defaultLogin: 'login',

  /**
   * @description 接口前缀含域名端口协议
   */
  baseURL: '/web',
  /**
   * @description 接口前缀含域名端口协议
   */
  qqMapKey: 'OPJBZ-BGWCF-DDWJE-NXUYH-WQGR3-63FPN',
}

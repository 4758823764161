<template>
  <ys-container :menuList="menuList">
    <top-header slot="header"></top-header>
    <div style="position: relative;">
      <routerContainer/>
    </div>
  </ys-container>
</template>

<script>
import routerContainer from './routerContainer.vue'
import topHeader from '_c/header/header.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Main',
  components: {
    routerContainer,
    topHeader,
  },
  computed: {
    ...mapGetters(['menuList'])
  }
}
</script>


<template>
  <div :class="classCls" @click="handleClick" :style="(disabled||loading)?null:styles">
    <div :class="`${classCls}-icon`">
      <Icon size="18" :type="perIcon" :color="(disabled||loading)?'':iconColor"/>
    </div>
    <div :class="boxCls">
      <p :class="`${boxCls}-title`">{{ title }}</p>
      <p :class="`${boxCls}-label`" v-if="!!label" @click="labelClick">{{ label }}</p>
    </div>
    <div class="extra"></div>
  </div>
</template>

<script>
const prefixCls = 'ys-cell-menu';

export default {
  name: "ys-cell-menu",
  props: {
    'perIcon': {type: String, default: ''},
    'iconColor': {type: String, default: ''},
    'styles': {type: Object, default: null},
    'extra': {type: String, default: ''},
    'title': {type: String, default: ''},
    'label': {type: String, default: ''},
    'disabled': {type: Boolean, default: false},
    'loading': {type: Boolean, default: false},
    'targetUrl': {type: String, default: null},
  },

  computed: {
    classCls() {
      return [prefixCls, (this.disabled||this.loading) ? 'disabled' : ''];
    },
    boxCls(){
      return [`${prefixCls}-box`]
    }
  },


  methods: {
    labelClick() {
      if (this.targetUrl) {
        this.$router.push({path: this.targetUrl})
      }
      return false;
    },
    handleClick() {
      if (this.disabled || this.loading) return //如果disabled=true 禁用click事件，如需强制使用，请用click.native原生事件
      this.$emit('on-click');
    }
  }
}
</script>

<style scoped lang="less">

</style>

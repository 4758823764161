export default [
  'ios-add',
  'md-add',
  'ios-add-circle',
  'ios-add-circle-outline',
  'md-add-circle',
  'ios-alarm',
  'ios-alarm-outline',
  'md-alarm',
  'ios-albums',
  'ios-albums-outline',
  'md-albums',
  'ios-alert',
  'ios-alert-outline',
  'md-alert',
  'ios-american-football',
  'ios-american-football-outline',
  'md-american-football',
  'ios-analytics',
  'ios-analytics-outline',
  'md-analytics',
  'logo-android',
  'logo-angular',
  'ios-aperture',
  'ios-aperture-outline',
  'md-aperture',
  'logo-apple',
  'ios-apps',
  'ios-apps-outline',
  'md-apps',
  'ios-appstore',
  'ios-appstore-outline',
  'md-appstore',
  'ios-archive',
  'ios-archive-outline',
  'md-archive',
  'ios-arrow-back',
  'md-arrow-back',
  'ios-arrow-down',
  'md-arrow-down',
  'ios-arrow-dropdown',
  'md-arrow-dropdown',
  'ios-arrow-dropdown-circle',
  'md-arrow-dropdown-circle',
  'ios-arrow-dropleft',
  'md-arrow-dropleft',
  'ios-arrow-dropleft-circle',
  'md-arrow-dropleft-circle',
  'ios-arrow-dropright',
  'md-arrow-dropright',
  'ios-arrow-dropright-circle',
  'md-arrow-dropright-circle',
  'ios-arrow-dropup',
  'md-arrow-dropup',
  'ios-arrow-dropup-circle',
  'md-arrow-dropup-circle',
  'ios-arrow-forward',
  'md-arrow-forward',
  'ios-arrow-round-back',
  'md-arrow-round-back',
  'ios-arrow-round-down',
  'md-arrow-round-down',
  'ios-arrow-round-forward',
  'md-arrow-round-forward',
  'ios-arrow-round-up',
  'md-arrow-round-up',
  'ios-arrow-up',
  'md-arrow-up',
  'ios-at',
  'ios-at-outline',
  'md-at',
  'ios-attach',
  'md-attach',
  'ios-backspace',
  'ios-backspace-outline',
  'md-backspace',
  'ios-barcode',
  'ios-barcode-outline',
  'md-barcode',
  'ios-baseball',
  'ios-baseball-outline',
  'md-baseball',
  'ios-basket',
  'ios-basket-outline',
  'md-basket',
  'ios-basketball',
  'ios-basketball-outline',
  'md-basketball',
  'ios-battery-charging',
  'md-battery-charging',
  'ios-battery-dead',
  'md-battery-dead',
  'ios-battery-full',
  'md-battery-full',
  'ios-beaker',
  'ios-beaker-outline',
  'md-beaker',
  'ios-beer',
  'ios-beer-outline',
  'md-beer',
  'ios-bicycle',
  'md-bicycle',
  'logo-bitcoin',
  'ios-bluetooth',
  'md-bluetooth',
  'ios-boat',
  'ios-boat-outline',
  'md-boat',
  'ios-body',
  'ios-body-outline',
  'md-body',
  'ios-bonfire',
  'ios-bonfire-outline',
  'md-bonfire',
  'ios-book',
  'ios-book-outline',
  'md-book',
  'ios-bookmark',
  'ios-bookmark-outline',
  'md-bookmark',
  'ios-bookmarks',
  'ios-bookmarks-outline',
  'md-bookmarks',
  'ios-bowtie',
  'ios-bowtie-outline',
  'md-bowtie',
  'ios-briefcase',
  'ios-briefcase-outline',
  'md-briefcase',
  'ios-browsers',
  'ios-browsers-outline',
  'md-browsers',
  'ios-brush',
  'ios-brush-outline',
  'md-brush',
  'logo-buffer',
  'ios-bug',
  'ios-bug-outline',
  'md-bug',
  'ios-build',
  'ios-build-outline',
  'md-build',
  'ios-bulb',
  'ios-bulb-outline',
  'md-bulb',
  'ios-bus',
  'ios-bus-outline',
  'md-bus',
  'ios-cafe',
  'ios-cafe-outline',
  'md-cafe',
  'ios-calculator',
  'ios-calculator-outline',
  'md-calculator',
  'ios-calendar',
  'ios-calendar-outline',
  'md-calendar',
  'ios-call',
  'ios-call-outline',
  'md-call',
  'ios-camera',
  'ios-camera-outline',
  'md-camera',
  'ios-car',
  'ios-car-outline',
  'md-car',
  'ios-card',
  'ios-card-outline',
  'md-card',
  'ios-cart',
  'ios-cart-outline',
  'md-cart',
  'ios-cash',
  'ios-cash-outline',
  'md-cash',
  'ios-chatboxes',
  'ios-chatboxes-outline',
  'md-chatboxes',
  'ios-chatbubbles',
  'ios-chatbubbles-outline',
  'md-chatbubbles',
  'ios-checkbox',
  'ios-checkbox-outline',
  'md-checkbox',
  'md-checkbox-outline',
  'ios-checkmark',
  'md-checkmark',
  'ios-checkmark-circle',
  'ios-checkmark-circle-outline',
  'md-checkmark-circle',
  'md-checkmark-circle-outline',
  'logo-chrome',
  'ios-clipboard',
  'ios-clipboard-outline',
  'md-clipboard',
  'ios-clock',
  'ios-clock-outline',
  'md-clock',
  'ios-close',
  'md-close',
  'ios-close-circle',
  'ios-close-circle-outline',
  'md-close-circle',
  'ios-closed-captioning',
  'ios-closed-captioning-outline',
  'md-closed-captioning',
  'ios-cloud',
  'ios-cloud-outline',
  'md-cloud',
  'ios-cloud-circle',
  'ios-cloud-circle-outline',
  'md-cloud-circle',
  'ios-cloud-done',
  'ios-cloud-done-outline',
  'md-cloud-done',
  'ios-cloud-download',
  'ios-cloud-download-outline',
  'md-cloud-download',
  'md-cloud-outline',
  'ios-cloud-upload',
  'ios-cloud-upload-outline',
  'md-cloud-upload',
  'ios-cloudy',
  'ios-cloudy-outline',
  'md-cloudy',
  'ios-cloudy-night',
  'ios-cloudy-night-outline',
  'md-cloudy-night',
  'ios-code',
  'md-code',
  'ios-code-download',
  'md-code-download',
  'ios-code-working',
  'md-code-working',
  'logo-codepen',
  'ios-cog',
  'ios-cog-outline',
  'md-cog',
  'ios-color-fill',
  'ios-color-fill-outline',
  'md-color-fill',
  'ios-color-filter',
  'ios-color-filter-outline',
  'md-color-filter',
  'ios-color-palette',
  'ios-color-palette-outline',
  'md-color-palette',
  'ios-color-wand',
  'ios-color-wand-outline',
  'md-color-wand',
  'ios-compass',
  'ios-compass-outline',
  'md-compass',
  'ios-construct',
  'ios-construct-outline',
  'md-construct',
  'ios-contact',
  'ios-contact-outline',
  'md-contact',
  'ios-contacts',
  'ios-contacts-outline',
  'md-contacts',
  'ios-contract',
  'md-contract',
  'ios-contrast',
  'md-contrast',
  'ios-copy',
  'ios-copy-outline',
  'md-copy',
  'ios-create',
  'ios-create-outline',
  'md-create',
  'ios-crop',
  'ios-crop-outline',
  'md-crop',
  'logo-css3',
  'ios-cube',
  'ios-cube-outline',
  'md-cube',
  'ios-cut',
  'ios-cut-outline',
  'md-cut',
  'logo-designernews',
  'ios-desktop',
  'ios-desktop-outline',
  'md-desktop',
  'ios-disc',
  'ios-disc-outline',
  'md-disc',
  'ios-document',
  'ios-document-outline',
  'md-document',
  'ios-done-all',
  'md-done-all',
  'ios-download',
  'ios-download-outline',
  'md-download',
  'logo-dribbble',
  'logo-dropbox',
  'ios-easel',
  'ios-easel-outline',
  'md-easel',
  'ios-egg',
  'ios-egg-outline',
  'md-egg',
  'logo-euro',
  'ios-exit',
  'ios-exit-outline',
  'md-exit',
  'ios-expand',
  'md-expand',
  'ios-eye',
  'ios-eye-outline',
  'md-eye',
  'ios-eye-off',
  'ios-eye-off-outline',
  'md-eye-off',
  'logo-facebook',
  'ios-fastforward',
  'ios-fastforward-outline',
  'md-fastforward',
  'ios-female',
  'md-female',
  'ios-filing',
  'ios-filing-outline',
  'md-filing',
  'ios-film',
  'ios-film-outline',
  'md-film',
  'ios-finger-print',
  'md-finger-print',
  'ios-flag',
  'ios-flag-outline',
  'md-flag',
  'ios-flame',
  'ios-flame-outline',
  'md-flame',
  'ios-flash',
  'ios-flash-outline',
  'md-flash',
  'ios-flask',
  'ios-flask-outline',
  'md-flask',
  'ios-flower',
  'ios-flower-outline',
  'md-flower',
  'ios-folder',
  'ios-folder-outline',
  'md-folder',
  'ios-folder-open',
  'ios-folder-open-outline',
  'md-folder-open',
  'ios-football',
  'ios-football-outline',
  'md-football',
  'logo-foursquare',
  'logo-freebsd-devil',
  'ios-funnel',
  'ios-funnel-outline',
  'md-funnel',
  'ios-game-controller-a',
  'ios-game-controller-a-outline',
  'md-game-controller-a',
  'ios-game-controller-b',
  'ios-game-controller-b-outline',
  'md-game-controller-b',
  'ios-git-branch',
  'md-git-branch',
  'ios-git-commit',
  'md-git-commit',
  'ios-git-compare',
  'md-git-compare',
  'ios-git-merge',
  'md-git-merge',
  'ios-git-network',
  'md-git-network',
  'ios-git-pull-request',
  'md-git-pull-request',
  'logo-github',
  'ios-glasses',
  'ios-glasses-outline',
  'md-glasses',
  'ios-globe',
  'ios-globe-outline',
  'md-globe',
  'logo-google',
  'logo-googleplus',
  'ios-grid',
  'ios-grid-outline',
  'md-grid',
  'logo-hackernews',
  'ios-hammer',
  'ios-hammer-outline',
  'md-hammer',
  'ios-hand',
  'ios-hand-outline',
  'md-hand',
  'ios-happy',
  'ios-happy-outline',
  'md-happy',
  'ios-headset',
  'ios-headset-outline',
  'md-headset',
  'ios-heart',
  'ios-heart-outline',
  'md-heart',
  'md-heart-outline',
  'ios-help',
  'md-help',
  'ios-help-buoy',
  'ios-help-buoy-outline',
  'md-help-buoy',
  'ios-help-circle',
  'ios-help-circle-outline',
  'md-help-circle',
  'ios-home',
  'ios-home-outline',
  'md-home',
  'logo-html5',
  'ios-ice-cream',
  'ios-ice-cream-outline',
  'md-ice-cream',
  'ios-image',
  'ios-image-outline',
  'md-image',
  'ios-images',
  'ios-images-outline',
  'md-images',
  'ios-infinite',
  'ios-infinite-outline',
  'md-infinite',
  'ios-information',
  'md-information',
  'ios-information-circle',
  'ios-information-circle-outline',
  'md-information-circle',
  'logo-instagram',
  'ios-ionic',
  'ios-ionic-outline',
  'md-ionic',
  'ios-ionitron',
  'ios-ionitron-outline',
  'md-ionitron',
  'logo-javascript',
  'ios-jet',
  'ios-jet-outline',
  'md-jet',
  'ios-key',
  'ios-key-outline',
  'md-key',
  'ios-keypad',
  'ios-keypad-outline',
  'md-keypad',
  'ios-laptop',
  'md-laptop',
  'ios-leaf',
  'ios-leaf-outline',
  'md-leaf',
  'ios-link',
  'ios-link-outline',
  'md-link',
  'logo-linkedin',
  'ios-list',
  'md-list',
  'ios-list-box',
  'ios-list-box-outline',
  'md-list-box',
  'ios-locate',
  'ios-locate-outline',
  'md-locate',
  'ios-lock',
  'ios-lock-outline',
  'md-lock',
  'ios-log-in',
  'md-log-in',
  'ios-log-out',
  'md-log-out',
  'ios-magnet',
  'ios-magnet-outline',
  'md-magnet',
  'ios-mail',
  'ios-mail-outline',
  'md-mail',
  'ios-mail-open',
  'ios-mail-open-outline',
  'md-mail-open',
  'ios-male',
  'md-male',
  'ios-man',
  'ios-man-outline',
  'md-man',
  'ios-map',
  'ios-map-outline',
  'md-map',
  'logo-markdown',
  'ios-medal',
  'ios-medal-outline',
  'md-medal',
  'ios-medical',
  'ios-medical-outline',
  'md-medical',
  'ios-medkit',
  'ios-medkit-outline',
  'md-medkit',
  'ios-megaphone',
  'ios-megaphone-outline',
  'md-megaphone',
  'ios-menu',
  'ios-menu-outline',
  'md-menu',
  'ios-mic',
  'ios-mic-outline',
  'md-mic',
  'ios-mic-off',
  'ios-mic-off-outline',
  'md-mic-off',
  'ios-microphone',
  'ios-microphone-outline',
  'md-microphone',
  'ios-moon',
  'ios-moon-outline',
  'md-moon',
  'ios-more',
  'ios-more-outline',
  'md-more',
  'ios-move',
  'md-move',
  'ios-musical-note',
  'ios-musical-note-outline',
  'md-musical-note',
  'ios-musical-notes',
  'ios-musical-notes-outline',
  'md-musical-notes',
  'ios-navigate',
  'ios-navigate-outline',
  'md-navigate',
  'ios-no-smoking',
  'ios-no-smoking-outline',
  'md-no-smoking',
  'logo-nodejs',
  'ios-notifications',
  'ios-notifications-outline',
  'md-notifications',
  'ios-notifications-off',
  'ios-notifications-off-outline',
  'md-notifications-off',
  'md-notifications-outline',
  'ios-nuclear',
  'ios-nuclear-outline',
  'md-nuclear',
  'ios-nutrition',
  'ios-nutrition-outline',
  'md-nutrition',
  'logo-octocat',
  'ios-open',
  'ios-open-outline',
  'md-open',
  'ios-options',
  'ios-options-outline',
  'md-options',
  'ios-outlet',
  'ios-outlet-outline',
  'md-outlet',
  'ios-paper',
  'ios-paper-outline',
  'md-paper',
  'ios-paper-plane',
  'ios-paper-plane-outline',
  'md-paper-plane',
  'ios-partly-sunny',
  'ios-partly-sunny-outline',
  'md-partly-sunny',
  'ios-pause',
  'ios-pause-outline',
  'md-pause',
  'ios-paw',
  'ios-paw-outline',
  'md-paw',
  'ios-people',
  'ios-people-outline',
  'md-people',
  'ios-person',
  'ios-person-outline',
  'md-person',
  'ios-person-add',
  'ios-person-add-outline',
  'md-person-add',
  'ios-phone-landscape',
  'md-phone-landscape',
  'ios-phone-portrait',
  'md-phone-portrait',
  'ios-photos',
  'ios-photos-outline',
  'md-photos',
  'ios-pie',
  'ios-pie-outline',
  'md-pie',
  'ios-pin',
  'ios-pin-outline',
  'md-pin',
  'ios-pint',
  'ios-pint-outline',
  'md-pint',
  'logo-pinterest',
  'ios-pizza',
  'ios-pizza-outline',
  'md-pizza',
  'ios-plane',
  'ios-plane-outline',
  'md-plane',
  'ios-planet',
  'ios-planet-outline',
  'md-planet',
  'ios-play',
  'ios-play-outline',
  'md-play',
  'logo-playstation',
  'ios-podium',
  'ios-podium-outline',
  'md-podium',
  'ios-power',
  'ios-power-outline',
  'md-power',
  'ios-pricetag',
  'ios-pricetag-outline',
  'md-pricetag',
  'ios-pricetags',
  'ios-pricetags-outline',
  'md-pricetags',
  'ios-print',
  'ios-print-outline',
  'md-print',
  'ios-pulse',
  'ios-pulse-outline',
  'md-pulse',
  'logo-python',
  'ios-qr-scanner',
  'md-qr-scanner',
  'ios-quote',
  'ios-quote-outline',
  'md-quote',
  'ios-radio',
  'ios-radio-outline',
  'md-radio',
  'ios-radio-button-off',
  'md-radio-button-off',
  'ios-radio-button-on',
  'md-radio-button-on',
  'ios-rainy',
  'ios-rainy-outline',
  'md-rainy',
  'ios-recording',
  'ios-recording-outline',
  'md-recording',
  'logo-reddit',
  'ios-redo',
  'ios-redo-outline',
  'md-redo',
  'ios-refresh',
  'md-refresh',
  'ios-refresh-circle',
  'ios-refresh-circle-outline',
  'md-refresh-circle',
  'ios-remove',
  'md-remove',
  'ios-remove-circle',
  'ios-remove-circle-outline',
  'md-remove-circle',
  'ios-reorder',
  'md-reorder',
  'ios-repeat',
  'md-repeat',
  'ios-resize',
  'md-resize',
  'ios-restauran',
  'ios-restaurant-outline',
  'md-restaurant',
  'ios-return-left',
  'md-return-left',
  'ios-return-right',
  'md-return-right',
  'ios-reverse-camera',
  'ios-reverse-camera-outline',
  'md-reverse-camera',
  'ios-rewind',
  'ios-rewind-outline',
  'md-rewind',
  'ios-ribbon',
  'ios-ribbon-outline',
  'md-ribbon',
  'ios-rose',
  'ios-rose-outline',
  'md-rose',
  'logo-rss',
  'ios-sad',
  'ios-sad-outline',
  'md-sad',
  'logo-sass',
  'ios-school',
  'ios-school-outline',
  'md-school',
  'ios-search',
  'ios-search-outline',
  'md-search',
  'ios-end',
  'ios-snd-outline',
  'md-sen',
  'ios-settings',
  'ios-settings-outline',
  'md-settings',
  'ios-share',
  'ios-share-outline',
  'md-share',
  'ios-share-alt',
  'ios-share-alt-outline',
  'md-share-alt',
  'ios-shirt',
  'ios-shirt-outline',
  'md-shirt',
  'ios-shuffle',
  'md-shuffle',
  'ios-skip-backward',
  'ios-skip-backward-outline',
  'md-skip-backward',
  'ios-skip-forward',
  'ios-skip-forward-outline',
  'md-skip-forward',
  'logo-skype',
  'logo-snapchat',
  'ios-snow',
  'ios-snow-outline',
  'md-snow',
  'ios-speedometer',
  'ios-speedometer-outline',
  'md-speedometer',
  'ios-square',
  'ios-square-outline',
  'md-square',
  'md-square-outline',
  'ios-star',
  'ios-star-outline',
  'md-star',
  'ios-star-half',
  'md-star-half',
  'md-star-outline',
  'ios-stats',
  'ios-stats-outline',
  'md-stats',
  'logo-steam',
  'ios-stopwatch',
  'ios-stopwatch-outline',
  'md-stopwatch',
  'ios-subway',
  'ios-subway-outline',
  'md-subway',
  'ios-sunny',
  'ios-sunny-outline',
  'md-sunny',
  'ios-swap',
  'md-swap',
  'ios-switch',
  'ios-switch-outline',
  'md-switch',
  'ios-sync',
  'md-sync',
  'ios-tablet-landscape',
  'md-tablet-landscape',
  'ios-tablet-portrait',
  'md-tablet-portrait',
  'ios-tennisball',
  'ios-tennisball-outline',
  'md-tennisball',
  'ios-text',
  'ios-text-outline',
  'md-text',
  'ios-thermometer',
  'ios-thermometer-outline',
  'md-thermometer',
  'ios-thumbs-down',
  'ios-thumbs-down-outline',
  'md-thumbs-down',
  'ios-thumbs-up',
  'ios-thumbs-up-outline',
  'md-thumbs-up',
  'ios-thunderstorm',
  'ios-thunderstorm-outline',
  'md-thunderstorm',
  'ios-time',
  'ios-time-outline',
  'md-time',
  'ios-timer',
  'ios-timer-outline',
  'md-timer',
  'ios-train',
  'ios-train-outline',
  'md-train',
  'ios-transgender',
  'md-transgender',
  'ios-trash',
  'ios-trash-outline',
  'md-trash',
  'ios-trending-down',
  'md-trending-down',
  'ios-trending-up',
  'md-trending-up',
  'ios-trophy',
  'ios-trophy-outline',
  'md-trophy',
  'logo-tumblr',
  'logo-tux',
  'logo-twitch',
  'logo-twitter',
  'ios-umbrella',
  'ios-umbrella-outline',
  'md-umbrella',
  'ios-undo',
  'ios-undo-outline',
  'md-undo',
  'ios-unlock',
  'ios-unlock-outline',
  'md-unlock',
  'logo-usd',
  'ios-videocam',
  'ios-videocam-outline',
  'md-videocam',
  'logo-vimeo',
  'ios-volume-down',
  'md-volume-down',
  'ios-volume-mute',
  'md-volume-mute',
  'ios-volume-off',
  'md-volume-off',
  'ios-volume-up',
  'md-volume-up',
  'ios-walk',
  'md-walk',
  'ios-warning',
  'ios-warning-outline',
  'md-warning',
  'ios-watch',
  'md-watch',
  'ios-water',
  'ios-water-outline',
  'md-water',
  'logo-whatsapp',
  'ios-wifi',
  'ios-wifi-outline',
  'md-wifi',
  'logo-windows',
  'ios-wine',
  'ios-wine-outline',
  'md-wine',
  'ios-woman',
  'ios-woman-outline',
  'md-woman',
  'logo-wordpress',
  'logo-xbox',
  'logo-yahoo',
  'logo-yen',
  'logo-youtube',
  'ios-loading',
]
<template>
  <div :class="classes">
    <Dropdown :placement="placement" transfer-class-name="regionDown" :transfer="transfer" style="width: 100%">
      <Input
          ref="iconInput"
          :suffix="showFlag?'ios-arrow-up':'ios-arrow-down'"
          v-model="iconType"
          :prefix="iconType"
          :disabled="disabled"
          @on-clear="clear"
          @click.native="showFlag=true"
          :clearable="clearable"
          style="cursor: pointer"
          readonly
          :placeholder="placeholder"/>
      <DropdownMenu slot="list" v-show="showFlag">
        <div :class="boxCls">
          <Input v-model="keyword" placeholder="输入关键词检索" clearable style="width: 98%;"/>
          <ul :class="iconListCls" @click="rowClick($event)">
            <li v-for="item in iconList" :key="item" :data-type="item" :title="item">
              <Icon :type="item" :data-type="item" size="28"></Icon>
            </li>
          </ul>
        </div>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script lang="ts">
import iconList from "./data.js";
import {clone} from '../../lib/index'

const prefixCls = 'ys-icon-select';

export default {
  name: 'ys-icon-select',
  props: {
    disabled: {type: Boolean, default: false},
    clearable: {type: Boolean, default: true},
    transfer: {type: Boolean, default: true},
    placeholder: {type: String, default: '请选择图标'},
    placement: {type: String, default: 'bottom-start'},
    value: {type: String, default: ''},
  },
  computed: {
    classes() {
      return prefixCls
    },
    iconList() {
      return !this.keyword ? clone(iconList) : clone(iconList).filter(item => item.indexOf(this.keyword) >= 0)
    },
    boxCls() {
      return [`${prefixCls}-box`]
    },
    iconListCls() {
      return [`${this.boxCls}-list`]
    },
    itemCls() {
      return [`${this.iconListCls}-item`]
    },
  },
  data() {
    return {
      iconType: '',
      keyword: '',
      prefix: 'ios-help-circle-outline',
      showFlag: false,
    }
  },
  watch:{
    value:function (){
      this.iconType = this.value;
    }
  },
  mounted() {
    this.iconType = this.value;
  },
  methods: {
    rowClick(event) {
      if (event.target.nodeName.toLowerCase() === 'i' || event.target.nodeName.toLowerCase() === 'li') {
        this.iconType = event.target.dataset.type;
        this.$emit('input', this.iconType);
      }
      this.showFlag = false;
    },
    openShow(e) {
      this.showFlag = true;
      e.stopPropagation();
    },
    clear() {
      this.iconType = '';
      this.$emit('input', '')
    },
  }
}
</script>

